import React, { Fragment, useCallback } from 'react';
import Measure from 'react-measure';
import styled from 'styled-components';
import {
	CHAT_ADD_UNSENT_MESSAGE,
	CHAT_REMOVE_FILE_ARCHIVE,
	CHAT_SET_CURRENT_MESSAGE,
	CHAT_SENT_ONE_ATTACHMENT,
	evtCHAT_MOVE_UPLOADED,
	evtCHAT_AGENT_PREVIEW,
	evtCHAT_HISTORY_SENT,
	CHAT_SEND_TYPING_STATUS
} from '../../redux/constants/constants';
import { spacePrefix } from '../../common/path'; // TODO: NOTE1
import {
	RPLY_ERRAND,
	RPLY_COMMENT,
	// RPLY_COLLABORATE,
	CHAT_crGuest,
	CHAT_crInvited,
	DEFAULT_CKEDITOR_HEIGHT_CHAT,
	CHAT_CKE_PLACEHOLDER_TEXT,
	START_TEMPLATE_DATA,
	END_TEMPLATE_DATA,
	CHAT_CKEDITOR_ID,
	RPLY_CHAT_SUMMARY
} from '../../common/v5/constants';
import { useCallbackWithValue } from '../../hooks/callback';
import {
	useBinaryBreakpoint,
	useBinaryMediaQueryString
} from '../../hooks/mediaquery';
import { AgentSingleAttachment, } from './Attachments';
import { FooterButtons } from './Footers';
import AgentSocket from '../../common/v5/agentsocket';
import AgentWs from '../../common/v5/socket/message';
import {
	chatHasExternalCollaboration,
	renderFileHtml,
	isGroupChatCollaborate,
	isDesktopMode
} from '../../common/v5/helpers';
import {
	AddAgentCtnr,
} from '../../containers/chat';
import Ckeditor from './Ckeditor';
import {
	customConfirm
} from '../../redux/actions/async/hmf';
import { I } from '../../common/v5/config';
import { ButtonsGroup } from '../../reactcomponents/Button';

const warnDeleteAttachment = I("Are you sure you want to delete attachment ")
	, warnBttnYes = 1
	, warnBttnNo = 2
	, yesBttnValue = {
		type: warnBttnYes
		, color: 'grey'
		, text: I('Yes')
	}
	, noBttnValue = {
		type: warnBttnNo
		, color: 'blue'
		, text: I('No')
	}
	, warnYesOrNoButtons = [
		yesBttnValue
		, noBttnValue
	]
	, shouldDeleteAttachment = {shouldDeleteAttachment: true}
	;

function confirmDeleteAttachment(props, type, attachmentID, attachmentFileName) {
	new Promise((resolve, reject) => {
		let warnBttns = warnYesOrNoButtons;
		resolve(props.dispatch(customConfirm(
			warnDeleteAttachment + attachmentFileName + "?"
			, warnBttns,
		))
		.then(({ button }) => {
			if (button === warnBttnYes) {
				return shouldDeleteAttachment;
			}
		}));
	})
	.then(shouldDeleteAttachment => {
		if(shouldDeleteAttachment) {
			props.dispatch({
				type: CHAT_REMOVE_FILE_ARCHIVE,
				chat: props.chat,
				id: attachmentID,
			});
		}
	});
}

const FilesQuePanel = ({children, hide}) => {
	return (
		<div className="filestosend-v5" hidden={hide}>
			<hr className='file-divider'/>
			{children}
			<div style={{clear:'both'}}/>
		</div>
	)
}

export class ChatAttachment extends React.Component {
	constructor(props) {
		super(props);
		this.onDeleteAttch = this.onDeleteAttch.bind(this);
	}
	onDeleteAttch(id, type, reply, fileName) {
		var props = this.props;
		confirmDeleteAttachment(props, type, id, fileName);
	}
	render() {
		let props = this.props
		, chat = this.props.chat
		;
		if (typeof chat.files === 'undefined' || chat.files == null &&
			chat.files.length == 0) {
			return null;
		}
		let filesToSend = [];
		const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");
		if (chat.files.length > 0) {
			$.each(chat.files, (id, v) => {
				filesToSend.push(
					<AgentSingleAttachment key={v.id} me={v.id}
						download={`${v.download}?q=view`}
						fileName={v.name}
						estSize={v.estSize}
						src={`${v.src}?q=view`}
						id={v.id}
						currentReply={props.currentReply}
						onSelectDelete={this.onDeleteAttch}
					/>
				);
			});
		}
		return (
			cflagAttachment ?
			<FilesQuePanel hide={filesToSend.length <= 0 || !props.showReplyPanel}>
				{filesToSend}
			</FilesQuePanel>
			:
			<div className="filestosend">
				{filesToSend}
				<div style={{clear:'both'}}/>
			</div>
		)
	}
}

const ActionButtons = ({
	chat,
	className,
	condition,
	currentReply,
	onButtonClick
}) => (
	<FooterButtons
		agentWorking={false}
		chat={chat}
		className={className}
		condition={condition}
		currentReply={currentReply}
		onButtonClick={useCallbackWithValue(chat, onButtonClick, 1)}
		showTweetWarn={false}
		tag='button'
		verticalView={false} // TODO: chat seem never has vertical view?
	/>
)

const StyledButtonsBase = styled(ActionButtons)`
	&.reply.buttons {
		display: flex;
		.button-row {
			display: flex;
			a, button {
				width: ${({ isBig }) => isBig ? '110px' : '105px'};
				font-size: ${({ isBig }) => isBig ? '11px' : '10px'};
				margin-left: 5px;
				padding: 5px;
				position: relative;
				display: inline-block;
				span {
					display: inline;
				}
			}
		}
	}
`
const StyledButtons = props => (
	<StyledButtonsBase isBig={useBinaryBreakpoint('up', 'xl')} {...props} />
)

// NOTE: Only work for native element. Custom component can't pass through ref.
const withMeasureWidthChange = Component => ({ onWidthChange, ...props }) => (
	<Measure
		bounds
		client={true}
		onResize={useCallback(
			({ bounds: { width } }) => {
				if (typeof onWidthChange === 'function') {
					onWidthChange(width)
				}
			},
			[onWidthChange]
		)}
	>
		{({ measureRef }) => <Component ref={measureRef} {...props} />}
	</Measure>
)

const ChatButtonsWrapper = withMeasureWidthChange('div')

const WrapperBase = styled.div`
	&.chatinput.wrap-add-a-message {
		button {
			font-size: 10px;
			margin-left: 5px;
			position: relative;
			width: 105px;
		}
		${({ xlMediaQuery }) => xlMediaQuery} {
			.form-add-message-wrap-input {
				button {
					width: 110px;
					font-size: 11px;
				}
			}
		}
	}
`
const Wrapper = props => (
	<WrapperBase
		className="chatinput wrap-add-a-message"
		id="chatinput"
		xlMediaQuery={useBinaryMediaQueryString('up', 'xl')}
		{...props}
	/>
)

export class ChatInput extends React.Component {
	constructor(props) {
		super(props);

		let state = {
			isProcessing: false,
			agentAssistBtnTop: 0,
			agentAssistBtnLeft: 0,
			showAgentAssistButton: false,
			selectedText: "",
		};
		for (var ce of props.errandListChat) {
			state[ce.sessionId] = {
				inputValue: ce.ui.currentMessage,
				chatSummaryInput: ce.ui.chatSummaryInput,
			}
		}
		this.state = state;

		this.handleCkeditorKeyDown = this.handleCkeditorKeyDown.bind(this);
		this.handleCkeditorChange = this.handleCkeditorChange.bind(this);
		this.handleSendButton = this.handleSendButton.bind(this);
		this.handleSendHistoryButton = this.handleSendHistoryButton.bind(this);
		this.sendAgentPreview = this.sendAgentPreview.bind(this);
		this.renderSendButtons = this.renderSendButtons.bind(this);
		this.handleAppendAnswer = this.handleAppendAnswer.bind(this);
		this.handleFileDrop = this.handleFileDrop.bind(this);
		this.handleChatEditorSize = this.handleChatEditorSize.bind(this);
		this.resetChatInput = this.resetChatInput.bind(this);
		this.handleToCloseWithSummary = this.handleToCloseWithSummary.bind(this);
		this.handleToCloseAndCreateErrand = this.handleToCloseAndCreateErrand.bind(this);
		this.handleSelectText = this.handleSelectText.bind(this);
		this.handleEditorFocus = this.handleEditorFocus.bind(this);
	}
	componentWillUnmount() {
		this.props.dispatch({
			type: CHAT_SET_CURRENT_MESSAGE,
			chatInputState: this.state,
		});
	}
	sendAgentPreview() {
		const editor = CKEDITOR.instances[CHAT_CKEDITOR_ID];
		if(editor && editor.document.getBody()) {
			if(Object.entries(editor.document.getBody()).length !== 0){
				var text = editor.document.getBody().getText();
				text = text.replace(CHAT_CKE_PLACEHOLDER_TEXT,'');
				text = text.replace(/\s/g,'');
				text = text.replace(/(\r\n|\n|\r)/gm,'');
				text = text.replace(/[\u21B5|\u000A]/g, ""); //remove this guy '↵'
				text = text.replace(new RegExp('<div></div>','g'),'');
				if(text == ""){
					text = editor.getData();
					text = text.replace(/\s/g,'');
					text = text.replace(new RegExp('&nbsp;', 'g'),'');
					text = text.replace(new RegExp('<div></div>','g'),'');
				}
				if(features["chat.enable-new-chat"]) {
					AgentWs.SendEvent(evtCHAT_AGENT_PREVIEW, {
						sessionId: this.props.chat.sessionId,
						messageLen: text.length,
					});
				} else {
					AgentSocket.SendEvent(evtCHAT_AGENT_PREVIEW, {
						sessionId: this.props.chat.sessionId,
						messageLen: text.length,
					});
				}
			}
		}
	}
	stripLeadingAndTrailingBlock(msg){
		var emptyLine = '<div>\xa0</div>';
		while(msg.indexOf(emptyLine) == 0){
			msg = msg.replace(emptyLine,'');
			msg = msg.trim();
		}
		msg = msg.replace(/\n/g,'');
		while(msg.lastIndexOf(emptyLine) != -1){
			if((msg.lastIndexOf(emptyLine)+ emptyLine.length) ==
				msg.length){
				msg = msg.slice(0, -emptyLine.length);
			} else break
		}
		msg = msg.replace(/<div[^>]*>/g,'');
		msg = msg.replace(/<\/div>/g,'<br>');
		var lastSubString = msg.substring(msg.length-'<br>'.length,
			msg.length);
		if(lastSubString == '<br>'){
			msg = msg.substring(0, msg.length-'<br>'.length);
		}
		return msg;
	}
	applyDefaultFontStyle(msg){
		const p = this.props, ckConf = p.ckEditorBox;
		let fontFamily = 'Verdana', fontSize = 12;
		if(ckConf.fontFamily){
			fontFamily = ckConf.fontFamily;
			if(fontFamily === "Calibri" || fontFamily === "calibri"){
				fontFamily = "Calibri,sans-serif,Arial";
			}
		}
		if(ckConf.fontSize){
			fontSize = ckConf.fontSize;
		}
		if (msg !== ""){
			msg = "<span style='font-family:" + fontFamily + ";font-size:" + fontSize + ";'>" + msg + "</span>";
			return msg;
		}else{
			return "";
		}
	}
	sendTypingStatus =(csId, status)=>{
		let NewSocket = AgentSocket;
		if(features["chat.enable-new-chat"]) {
			NewSocket = AgentWs;
		}
		this.props.onHandleTypingStatus(status, this.props.currentChatErrandId);
		NewSocket.SendEvent(CHAT_SEND_TYPING_STATUS, {
			status: (status === 1 ? "on" : "off"),
			sessionId: csId,
		}, ( ack ) => {
			if(typeof ack.error !== 'undefined') {
				console.debug("Agent typing status fail to send: " + ack.error);
			}
		});
	}
	handleCkeditorKeyDown(e) {
		const p = this.props;
		if (chatHasExternalCollaboration(p.currentReply)) {
			return
		}
		// TODO: this need to be optimized because we should not put too much
		// presure on keydown event because very obvious it can slow the user
		// experience on chat input box.
		let shortcuts = p.shortcutKeys, val = "", text = "";
		const editor = CKEDITOR.instances[CHAT_CKEDITOR_ID];
		if (editor) {
			val = editor.getData();
			text = editor.document.getBody().getText();
			let erData = p.chat.errand.data;
			if(typeof erData !== 'undefined'){
				if(erData.sourceId === Workflow.Errand.SERVICE_FACEBOOK){
					if( p.fbKeyTypingStatus !== 1 ){
						this.sendTypingStatus(erData.sessionId, 1);
					}
				}else if(erData.sourceId === Workflow.Errand.SERVICE_WHATSAPP){
					if(typeof p.chat.canSendMessageToWA !== 'undefined' && !p.chat.canSendMessageToWA){
						p.showError(I("This errand has expired the 24 hours reply restriction of the WhatsApp business API. Replying to the errand is no longer possible."));
						console.debug("This message is outside of 24 hour window");
						return;
					}
				}
			}
		}
		//ckeditor configured for shiftEnterMode
		if (e.data.keyCode == 13) {
			clearTimeout(this.previewTimeout);
			if (p.currentReply !== RPLY_COMMENT && p.currentReply !== RPLY_CHAT_SUMMARY) {
				if(this.canSend(CHAT_CKE_PLACEHOLDER_TEXT)){
					this.send(val);
				}
			}else{
				if(p.currentReply === RPLY_COMMENT && this.canSend()){
					p.onAddErrandNotes(val, text);
				}
			}
			e.cancel();// avoid newline to be sent out
			return;
		} else{
			if(shortcuts && shortcuts.length > 0){
				let key = e.data.domEvent.$.keyCode, currKeyBind = {}, possibleSc = [];
				$.each(shortcuts, (k, sc) => {
					if(sc.Key == key){
						possibleSc.push(sc);
					}
				});

				//Multiple shortcuts can possibly share the same end key
				$.each(possibleSc, (k, psc) => {
					if(psc.UseCtrl == e.data.domEvent.$.ctrlKey &&
					psc.UseAlt == e.data.domEvent.$.altKey &&
					psc.UseShift == e.data.domEvent.$.shiftKey &&
					psc.UseMeta == e.data.domEvent.$.metaKey){
						currKeyBind = psc;
					}
				});

				if(currKeyBind.UseCtrl == e.data.domEvent.$.ctrlKey &&
					currKeyBind.UseAlt == e.data.domEvent.$.altKey &&
					currKeyBind.UseShift == e.data.domEvent.$.shiftKey &&
					currKeyBind.UseMeta == e.data.domEvent.$.metaKey)
				{
					window.setTimeout(function() {
						this.handleAppendAnswer(currKeyBind.Answer, currKeyBind.Attachments);
					}.bind(this),0);
					e.cancel();
					return;
				}
			}
		}
		if(this.props.currentReply === RPLY_ERRAND){
			clearTimeout(this.previewTimeout);
			this.previewTimeout = setTimeout(this.sendAgentPreview, 400);
		}
	}
	handleAppendAnswer(answer, files) {
		const editor = CKEDITOR.instances[CHAT_CKEDITOR_ID];
		if(editor){
			let currentAns = editor.getData();
			let appended = currentAns + answer;
			editor.setData(appended);
			if(files) {
				this.props.onUploadChatAttachments(this.props.chat, files);
			}
		}
	}
	handleCkeditorChange(e, reformat) {
		const { currentReply } = this.props;
		if (currentReply === RPLY_ERRAND || isGroupChatCollaborate(currentReply)) {
			let v = CKEDITOR.instances[CHAT_CKEDITOR_ID].getData();
			this.setState({
				[this.props.chat.sessionId]: {
					inputValue: v
				},
			});
		} else if (chatHasExternalCollaboration(currentReply)) {
			// TODO: why internal comment for chat is different from errand? No
			// control component? Unacceptable.
			const { onAnswerChange } = this.props;
			if (typeof onAnswerChange === "function") {
				const { editor } = e;
				onAnswerChange(
					editor.getData(),
					editor.document.getBody().getText(),
					reformat
				);
			}
		} else if (currentReply === RPLY_CHAT_SUMMARY) {
			const {chat, onChangeSummary} = this.props;
			let v = CKEDITOR.instances[CHAT_CKEDITOR_ID].document.getBody().getText()
			this.setState({
				[chat.sessionId]: {
					chatSummaryInput: v
				},
			});
			if (typeof onChangeSummary === "function") {
				onChangeSummary(chat.sessionId, v);
			}
		}
	}
	textForFile(file) {
		let path;
		// TODO use encrypted link instead.
		if (file.kind == 'archive' || file.kind == 'upload') {
			//path = file.download;
			//Note: according to commit `6cba23992b2a4a64489bee0fc4d1195f8279535d`
			//"action" variable has not been used!! which unfortunately caused the attachment
			//sending fail in Facebook, whatsapp, instagram and twitter
			//Let's handle the attachment sending from raven and chatd and remove the below code

			//} else if (file.kind == 'upload') {
			//let erData = this.props.chat.errand.data;
			//let action = "download";
			//if(typeof erData !== 'undefined' &&
			//	(erData.sourceId === Workflow.Errand.SERVICE_WHATSAPP
			//		|| erData.sourceId === Workflow.Errand.SERVICE_FACEBOOK
			//		|| erData.sourceId === Workflow.Errand.SERVICE_INSTAGRAM)){
			//	action = "view";
			//}
			path = file.download;
		} else if (file.kind == 'knowledgebase') {
			path = file.chaturl;
		} else {
			alert("FIXME unsupported file.kind "+ file.kind);
			return JSON.stringify(file);
		}
		let baseURL = this.props.chat.BaseURL;
		let fileURL = this.getBaseURL(baseURL);
		let baseDomain = fileURL.match(/(https?:\/\/[^/]+)/)[1];

		let url = baseDomain + path +
			(path.indexOf('?')<0?'?':'&') + 't=' + this.props.chat.Secret;
		return renderFileHtml(url, file.name);
	}
	getCloudURLSpace = (baseURL, fileURL) =>{
		let spc = "";
		// Notes: no need line below since the path from backend already with space prefix
		/* if ( baseURL.indexOf("cloud.cention.com") || baseURL.indexOf("cloud.cust.cention.se") ) {
			if( fileURL.indexOf("/s/") !== -1 ) {
				spc = spacePrefix;
			}
		} */
		return spc;
	}
	getBaseURL(url){
		if(url !== ""){
			// TODO: NOTE1: this part need to be rethink a bit because there
			// should NOT be any space needed in authenticated V5 page
			// front-end. Ultimately there won't have anything /s/<space> appear
			// in this component and no hack needed to remove it. CCC-3560.
			//if (spacePrefix) {
			//	return this.setupCentionBaseURLandSpace(url).CentionBaseURL;
			//}
			return url;
		}else{
			return location.origin;
		}
	}
	setupCentionBaseURLandSpace(baseURL) {
		let urlInfo = {};
		urlInfo.CentionBaseURL = baseURL;
		// TODO: get this constants from golang backend cloud pkg.
		if ( baseURL.indexOf("cloud.cention.com") || baseURL.indexOf("cloud.cust.cention.se") ) {
			let i = baseURL.indexOf("/s/");
			if ( i != -1 ) {
				urlInfo.spacePrefix = baseURL.substr(i);
				urlInfo.CentionBaseURL = baseURL.substr(0, i);
			}
		}
		return urlInfo;
	}
	sendFile(file) {
		let sendText = (file) => {
			let imgText = this.textForFile(file);
			let umid = this.props.sendMessage(this.props.chat, imgText, "", 0, this.props.dispatch);
			this.props.dispatch({
				type: CHAT_ADD_UNSENT_MESSAGE,
				sessionId: this.props.chat.sessionId,
				umid: umid,
				text: imgText,
			});
			this.props.dispatch({
				type: CHAT_SENT_ONE_ATTACHMENT,
				chat: this.props.chat,
				id: file.id,
			});
			this.setState({
				[this.props.chat.sessionId]: {
					inputValue: ""
				},
			});
		};
		sendText(file);
	}
	send(text, plainTxt) {
		let files = this.props.chat.files;
		$.each(files, (i, file) => {
			this.sendFile(file);
		});
		let waTemplCode = this.props.waTemplCode;
		text = this.processImage(text);
		text = this.stripLeadingAndTrailingBlock(text);
		text = this.applyDefaultFontStyle(text);
		if(plainTxt){
			plainTxt = plainTxt.trim();
			if(plainTxt.length == 0){
				return;
			}
		}else{
			if(typeof text === 'undefined')
				return;
			if (text === "" && text.length === 0) {
				return;
			}
		}
		this.resetChatInput();
		let umid = "";
		let templateId = 0;
		if(typeof this.props.template !== 'undefined' && this.props.template !== null){
			templateId = this.props.template
		}
		if(this.props.canTranslate){
			if(this.props.toLang && this.props.fromLang){
				if(this.props.toLang !== this.props.fromLang){
					this.props.onTranslation("reverseTranslation", text, this.props.fromLang , this.props.toLang)
					.then(html => {
						if(html) {
							umid = this.props.sendMessage(this.props.chat, html[0], waTemplCode, templateId, this.props.dispatch);
							this.doSendMessage(umid, text);
						}
					});
					return;
				}
			}
		}
		umid = this.props.sendMessage(this.props.chat, text, waTemplCode, templateId, this.props.dispatch);
		this.doSendMessage(umid, text);
		let erData = this.props.chat.errand.data;
		if(typeof erData !== 'undefined' &&
			(erData.sourceId === Workflow.Errand.SERVICE_WHATSAPP || erData.sourceId === Workflow.Errand.SERVICE_FACEBOOK)){
			//template whatsapp interactive need to reset
			//Facebook quickReply also used it
			this.props.onTemplateIdReset(0, erData.id);
		}
	}
	processImage(text) {
		//Note: To remove dropped image before send as it handled as attachment and and shown inline in message.
		let msg = $('<p>').html(text).find('img.cke-dropped').remove().end().html();
		return msg;
	}
	handleOpenAgentAssistSum = () => {
		if(this.props.onToggleRewritePanelAnswer) {
			const defaultAction = "summarize";
			this.props.onToggleRewritePanelAnswer(true, this.state.selectedText,
				this.props.areaId, false, false, false, '', '',
				this.props.errandId, defaultAction);
		}
	}
	doSendMessage(umid, text){
		// Strip off the template data from the message (if any)
		let sidx = text.indexOf(START_TEMPLATE_DATA)
		let eidx = text.indexOf(END_TEMPLATE_DATA)
		if (sidx != -1 && eidx != -1 && eidx > sidx) {
			text = text.substring(0, sidx) + text.substring(eidx+END_TEMPLATE_DATA.length, text.length);
		}
		this.props.dispatch({
			type: CHAT_ADD_UNSENT_MESSAGE,
			sessionId: this.props.chat.sessionId,
			umid: umid,
			text: text
		});
		let p = this.props;
		let erData = p.chat.errand.data;
		if(typeof erData !== 'undefined' && erData.sourceId === Workflow.Errand.SERVICE_FACEBOOK){
			if( p.fbKeyTypingStatus !== 0 ){
				this.sendTypingStatus(erData.sessionId, 0);
			}
		}
	}
	resetChatInput() {
		const editor = CKEDITOR.instances[CHAT_CKEDITOR_ID];
		if(editor){
			editor.setReadOnly(false);
			this.setState({
				[this.props.chat.sessionId]: {
					inputValue: ""
				},
			});
			editor.focus();
		}
	}
	canSend(placeholder){
		const editor = CKEDITOR.instances[CHAT_CKEDITOR_ID];
		if(this.props.chat.files.length > 0){
			return true;
		}else{
			if(editor){
				let a = editor.document.getBody().getText();
				if(placeholder){
					a = a.replace(placeholder,'');
				}
				a = a.replace(/\s/g,'');
				a = a.replace(/(\r\n|\n|\r)/gm,'');
				a = a.replace(/[\u21B5|\u000A]/g, ""); //remove this guy '↵'
				a = a.replace(new RegExp('<div></div>','g'),'');
				if(a == ""){
					let b = editor.getData();
					b = b.replace(/\s/g,'');
					b = b.replace(new RegExp('&nbsp;', 'g'),'');
					b = b.replace(new RegExp('<div></div>','g'),'');
					return b != ""
				}
				return a != "";
			}
			return false;
		}
	}
	handleSendButton() {
		const { currentReply, onAddErrandNotes } = this.props;
		let html = "", text = "";
		if (CKEDITOR.instances[CHAT_CKEDITOR_ID]) {
			html = CKEDITOR.instances[CHAT_CKEDITOR_ID].getData();
			text = CKEDITOR.instances[CHAT_CKEDITOR_ID].document.getBody().getText();
		}
		if (currentReply !== RPLY_COMMENT) {
			if(this.canSend(CHAT_CKE_PLACEHOLDER_TEXT)){
				this.send(html);
			}
		} else {
			if(this.canSend()){
				onAddErrandNotes(html, text);
			}
		}
	}
	handleSendHistoryButton() {
		let NewSocket = AgentSocket;
		if(features["chat.enable-new-chat"]) {
			NewSocket = AgentWs;
		}
		NewSocket.SendEvent(evtCHAT_HISTORY_SENT, {
			sessionId: this.props.chat.sessionId,
		}, (ack) => {
			if (ack.error) {
				let msg = I("Could not send chat history. Please contact support. Chat Id: {CHAT_ID}")
				.replace('{CHAT_ID}', this.props.chat.sessionId);
				this.props.showError(msg);
			} else if(ack.status && ack.status == "ALREADY_SET"){
				let msg = "A copy of the chat will be sent";
				if(this.props.chat.errand && this.props.chat.errand.data &&
					this.props.chat.errand.data.fromAddress.length > 0 ){
					msg = msg + " to " +
							this.props.chat.errand.data.fromAddress;
				}
				this.props.showError(msg);
			}
		});
	}
	handleToCloseAndCreateErrand = async () => {
		const { isProcessing } = this.state;
		const { chat, currentChatErrandId, onCreateErrand, onCloseWithSummary } = this.props;
	
		if (isProcessing) return; 
	
		// prevent further clicks
		this.setState({ isProcessing: true }, async () => {
			try {
				await onCreateErrand(chat.sessionId);
				await onCloseWithSummary(currentChatErrandId, chat.sessionId, this.state[chat.sessionId].chatSummaryInput);
			} catch (error) {
				console.error("Error processing errand creation and closing:", error);
			} 
		});
	};
	handleToCloseWithSummary() {
		if(this.props.currentReply === RPLY_CHAT_SUMMARY && this.props.showReplyPanel) {
			if(typeof this.state[this.props.chat.sessionId].chatSummaryInput !== "undefined") {
				const text = this.state[this.props.chat.sessionId].chatSummaryInput;
				const txtSummary = this.stripLeadingAndTrailingBlock(text);
				if(txtSummary === "" || txtSummary.length === 0){
					alert(I("Summary cannot be empty"));
					return;
				}
				this.props.onCloseWithSummary(this.props.currentChatErrandId, this.props.chat.sessionId, this.state[this.props.chat.sessionId].chatSummaryInput);
			} else {
				alert(I("Summary cannot be empty"));
			}
		} else {
			this.props.onToCloseWithSummary();
		}
	}
	renderSendButtonsBase(chat, showSendHistoryButton) {
		const {
			buttonCondition,
			onButtonClick,
			...p
		} = this.props;
		const { currentReply } = p;
		let sourceId = this.props.chat.errand.data.sourceId
		let allowCloseWithSummary = false, isClose = false;
		if(this.props.chat.errand && this.props.chat.errand.data && this.props.chat.errand.data.closed === true){
			isClose = true;
		}
		if(cflag.IsActive("2024-04-19.CEN-92.chat-summary-for-history") && !isClose) {
			allowCloseWithSummary = true;
		} else {
			allowCloseWithSummary = false;
		}

		if (chatHasExternalCollaboration(currentReply)) {
			return (
				<StyledButtons
					chat={chat}
					condition={buttonCondition}
					currentReply={currentReply}
					onButtonClick={onButtonClick}
				/>
			);
		}
		let sendBtn = ""
		, sendTxt = I('Send')
		, sendHistoryBtn = ""
		, closeWithSummTxt = ""
		, closeWithSummaryBtn = ""
		, closeCreateErrandBtn=""
		, clearBtn = ""
		, showClearBtn = false
		, hideSend = false
		, disable = '';
		let bottomPadding = '10px';
		if(p.internatChatWindow){
			bottomPadding = '5px';
		}

		if(!chat.dead || currentReply === RPLY_COMMENT || currentReply === RPLY_CHAT_SUMMARY){
			if(currentReply === RPLY_COMMENT){
				sendTxt = I('Save');
			}
			if(chat.errand.data.sourceId === Workflow.Errand.SERVICE_WHATSAPP ){
				if(typeof chat.canSendMessageToWA !== 'undefined' && !chat.canSendMessageToWA){
					disable = true
				}
			}
			sendBtn = <button data-qa-id="chat-send-btn" className={"btn-blue"} onClick={this.handleSendButton} disabled={disable}>{sendTxt}</button>;
		}
		if (currentReply === RPLY_CHAT_SUMMARY) {
			showClearBtn = true;
			hideSend = true;
		}
		if ((currentReply === RPLY_ERRAND
			|| isGroupChatCollaborate(currentReply)) && !chat.dead) {
			if (showSendHistoryButton) {
				sendHistoryBtn = <button data-qa-id="chat-send-history-btn" className={"btn-blue"} onClick={this.handleSendHistoryButton}>{I('Send history')}</button>;
			}
		}
		const renderCloseWithSummaryButton = () => {
			let closeButtonText = (
				<span>
					<i className="icon-comment" /> {I("Close with summary")}
				</span>
			);
		
			if (currentReply === RPLY_CHAT_SUMMARY) {
				closeButtonText = <span>{I("Send and close errand")}</span>;
			}
		
			return (
				<button
					data-qa-id="chat-send-btn"
					className="btn-blue"
					onClick={this.handleToCloseWithSummary}
					disabled={disable}
					style={{ width: 'auto' }}
				>
					{closeButtonText}
				</button>
			);
		};
		const renderCloseCreateErrand = () => {
			let closeButtonText = (
				<span>
					<i className="icon-comment" /> {I("Close and Create Errand")}
				</span>
			);
		
			closeButtonText = <span>{I("Close and Create Errand")}</span>;

			return (
				<button
					data-qa-id="chat-send-btn"
					className="btn-blue"
					onClick={this.handleToCloseAndCreateErrand}
					disabled={disable}
					style={{ width: 'auto' }}
				>
					{closeButtonText}
				</button>
			);
		};
		if ( allowCloseWithSummary && sourceId === 3 ) {
			clearBtn = (
				<button
					data-qa-id="chat-clear-btn"
					className="btn-grey"
					onClick={this.resetChatInput}
				>
					{I('Clear')}
				</button>
			);
			closeWithSummaryBtn = renderCloseWithSummaryButton();
			closeCreateErrandBtn= renderCloseCreateErrand();
		}
		
		return (
			<Fragment>
			{!hideSend && sendBtn} 
			{sendHistoryBtn} 
			{showClearBtn && clearBtn} 
			{features["close-and-create-errand"] ? closeCreateErrandBtn : closeWithSummaryBtn}
		  </Fragment>
			);
	}
	renderSendButtons(chat, showSendHistoryButton) {
		if (!chat) {
			return null;
		}
		return (
			<ChatButtonsWrapper
				className="chat-button-wrapper"
				onWidthChange={this.props.onReplyButtonsWidthChange}
			>
				{this.renderSendButtonsBase(chat, showSendHistoryButton)}
			</ChatButtonsWrapper>
		);
	}
	handleFileDrop(files) {
		this.props.onDropFiles(this.props.chat, files);
	}
	handleChatEditorSize(height) {
		this.props.onRecordEditorSizes({chat: height});
	}
	renderJoined() {
		const {
			currentReply,
			hideSpellchecker,
			internalComment,
			...p
		} = this.props;
		let currentInput = "";
		let bg = 'white';
		let chat = this.props.chat, chatNotice = "";
		switch (currentReply) {
			case RPLY_COMMENT:
				currentInput = internalComment.internal_comment;
				break;
			case RPLY_ERRAND:
				// fallthrough to default
			default:
				if (chatHasExternalCollaboration(currentReply)) {
					currentInput = p.ckeditorContent;
				} else {
					if (this.props.chat && this.state[this.props.chat.sessionId]) {
						currentInput = this.state[this.props.chat.sessionId].inputValue;
					}
				}
				break;
		}
		let textAreaStyle = {
			width: (p.internatChatWindow ? '70%' : 'calc(100% - 150px)'),
			border: '1px solid #c4c4c4',
			height: '30px',
			minHeight: '30px',
			paddingLeft: '14px',
			paddingRight: '14px',
			color: '#6d6d6d',
			fontSize: '12px',
			fontWeight: '500',
			outline: '0'
		}
		let chatStatusStyle = {
			fontSize: '12px',
			display: 'inline-block',
			width: '100%',
			float: "left",
			clear: "both",
			verticalAlign: "middle",
			color: "#6d6d6d",
			marginLeft: "0px"
		}
		if (chat) {
			if (chat.dead) {
				if (chat.notice) {
					chatNotice = chat.notice;
				} else if (chat.Role == CHAT_crInvited) {
					chatNotice = I("This chat has ended. You were invited.")
				} else {
					chatNotice = I("This chat has ended.")
				}
			} else if (chat.Role == CHAT_crInvited) {
				chatNotice = I("You are invited to join this chat.")
			}
		}
		/* Ckeditor config */
		const ckConf = p.ckEditorBox;
		let opts = {}, fontFamily = ckConf.fontFamily, fontSize = ckConf.fontSize, langs = ckConf.languageSrc,
			areaArchive = p.archiveImgs, replyFormClass, isErrand, isExtFwd, isCollaborate,
			isComment, showTextarea,
			subjectDOM = null, ckeClass, bgColor, hideSalPreview = true, hideSigPreview = true;

		let className, placeholder = CHAT_CKE_PLACEHOLDER_TEXT;
		if (currentReply == RPLY_COMMENT) {
			className = 'forinternalcomment';
			bgColor = '#FFF9EC';
			placeholder = '';
		} else if (currentReply == RPLY_CHAT_SUMMARY) {
			placeholder = I("Write chat summary");
		}

		let height = DEFAULT_CKEDITOR_HEIGHT_CHAT;
		if(this.props.preferredChatBoxHeight){
			height = this.props.preferredChatBoxHeight;
		}

		if (!this.props.showReplyPanel) {
			height = DEFAULT_CKEDITOR_HEIGHT_CHAT;
		}

		let showSendHistoryButton = false, isOwner = chat.errand.data.agentId == initialChatData.agentID
		if (isOwner && features['chat.optional-history-send-to-client'] && !features['chat.history-send-to-client']) {
			showSendHistoryButton = true;
		}

		let bottomPadding = '10px';
		if(p.internatChatWindow){
			bottomPadding = '5px';
		}

		let editorWrapperStyle = {};
		let chatInputWidth = "calc(100% - 250px)";
		if(isDesktopMode()) {
			chatInputWidth = "calc(100% - 280px)";
		}
		const agentAssistShortCutBtnStyle = {
			top: this.state.agentAssistBtnTop + 100,
			left: this.state.agentAssistBtnLeft,
		}
		return <Wrapper>
			<div className="form-add-message-wrap-input">
				{
					this.renderSendButtons(chat, showSendHistoryButton)
				}
				{
					<div style={editorWrapperStyle} className="chat-editor-wrapper">
						<Ckeditor
							hideSpellchecker={hideSpellchecker}
							id={CHAT_CKEDITOR_ID}
							isChat={chat}
							data-qa-id={CHAT_CKEDITOR_ID}
							startupFocus={true}
							control={true}
							bgColor={bgColor}
							myClass={ckeClass}
							hide={chat.dead && (currentReply !== RPLY_COMMENT && currentReply !== RPLY_CHAT_SUMMARY)}
							defaultFontFamily={fontFamily}
							toolbarID={p.toolbarID}
							defaultFontSize={fontSize}
							placeHolderText={placeholder}
							defaultContent={currentInput}
							spellLanguages={langs}
							height={height}
							resizeMinHeight={DEFAULT_CKEDITOR_HEIGHT_CHAT}
							showResize={true}
							recordSize={true}
							onRecordEditorSize={this.handleChatEditorSize}
							simpleToolbar={false}
							fileArchiveImages={areaArchive}
							onKeydown={this.handleCkeditorKeyDown}
							onChange={this.handleCkeditorChange}
							onDragnDropFiles={this.props.onDragDropFiles}
							onFileDrop={this.handleFileDrop}
							showReplyPanel={p.showReplyPanel}
							onSelectText={this.handleSelectText}
							onFocus={this.handleEditorFocus}
							>
							{features["openai-rewrite-answer"] && this.state.showAgentAssistButton && (
								<ButtonsGroup
								customStyle={agentAssistShortCutBtnStyle}
								onClickPr={this.handleOpenAgentAssist}
								onClickSum={this.handleOpenAgentAssistSum}
							  />
							)}
							</Ckeditor>
						{chat && (chat.dead || chat.Role == CHAT_crInvited) &&
							(currentReply == RPLY_ERRAND ||
								isGroupChatCollaborate(currentReply)) &&
							<p style={chatStatusStyle}>{chatNotice}</p>
						}
					</div>
				}
			</div>
		</Wrapper>;
	}
	renderInvited(sessionId) {
		let acceptInvite = () => { this.props.answerInvite(sessionId, true); }
		, rejectInvite = () => { this.props.answerInvite(sessionId, false); }
		;

		return <Wrapper>
				<div className="form-add-message-wrap-input">
					<button className="btn-blue answerInvite" onClick={acceptInvite}>{I('Join')}</button>
					<button className="btn-blue answerInvite" onClick={rejectInvite}>{I('Reject')}</button>
				</div>
		</Wrapper>;
	}
	handleSelectText = (text, position, invalid) => {
		const cleanedSelection = text.replace(/[\n\r]/g, '');
		if(cleanedSelection.length > 0){
			this.setState({
				selectedText: text,
				showAgentAssistButton: true,
				agentAssistBtnTop: position.top,
				agentAssistBtnLeft: position.left
			});
		} else {
			this.setState({
				selectedText: "",
				showAgentAssistButton: false,
			});
		}
		if(invalid) {
			this.setState({
				showAgentAssistButton: false
			});
		}
	}
	handleEditorFocus = () => {
		this.setState({
			selectedText: "",
			showAgentAssistButton: false,
		});
	}
	handleOpenAgentAssist = () => {
		if(this.props.onToggleRewritePanelAnswer) {
			const defaultAction = "proof-read";
			this.props.onToggleRewritePanelAnswer(true, this.state.selectedText,
				this.props.chat.errand.data.area, false, false, true, '', '',
				this.props.currentChatErrandId, defaultAction);
		}
	}
	render() {
		if (!this.props.chat){
			return null;
		}

		let chat = this.props.chat
		, isOwner = chat.errand.data.agentId == initialChatData.agentID
		, joined = chat.Role == CHAT_crGuest
		;

		if (isOwner || joined) {
			return this.renderJoined();
		}
		if (chat && !chat.dead) {
			return this.renderInvited(chat.sessionId);
		}
	}
}
