import React, {
	Fragment,
	PureComponent,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { branch, mapProps, renderComponent } from 'recompose';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import styled from 'styled-components';
import { convertToYesOrNo, convertToYesOrNoId } from '../../common/helpers';
import {
	useCallbackWithValue,
	useCallbackMultiValues
} from '../../hooks/callback';
import { useSerialToArrayInt } from '../../hooks/select';
import flexbox from '../../styles/flexbox';
import {
	composeWithDisplayName,
	createWithMountCondition,
	omitProps,
	withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import {default as SquareCheckbox, NamedCheckbox} from '../../reactcomponents/SquareCheckbox';
import { NoFlipMultiSelect, SingleSelectDD } from '../../reactcomponents/Dropdown';
import {
	BooleanSelect,
	ReadOnly,
	FormInputWithLabelRow,
	JSONForm,
	ReadOnlyInputRow,
	TextInputRow,
	SelectInputRow,
	CheckboxInputRow,
	AvatarInputRow,
	withLabel
} from '../../reactcomponents/Form';
import Button from '../../reactcomponents/Button';
import { IconTimestampPicker } from '../../reactcomponents/DatePicker';
import {
	AgentFormCtnr,
} from '../../views/v5/adminCtnr';
import {
	TXT_ACCESS_KEY_ID,
	TXT_ACCESS_KEYS,
	TXT_CREATE,
	TXT_EXPIRY_DATE
} from '../../common/texts';
import {
	formatValueToNameObj,
	isValidEmail,
	isValidPhoneNo,
	isValidPassword
} from '../../common/v5/helpers';
import { ACTION_DELETE, ListContentWrapper, ActionsWrapper, Edit, Remove } from '../../reactcomponents/Admin';
import { LibrariesSelect } from '../../reactcomponents/Chatbot';
import { PaginationSize } from '../../reactcomponents/SimpleTable';
import {
	UNSELECT,
	DARK_MODE_APPEARANCE,
	INPUT_EMPTY_WARNING,
	MESSAGE_INVALID_EMAIL,
	MESSAGE_INVALID_PASSWORD_FORMAT,
	MESSAGE_INVALID_PHONE_NO,
	MESSAGE_2FA_TOKEN,
	UPLOAD_AGENT_STARTED,
	UPLOAD_AGENT_INPROGRESS,
	USE_XHR_CREATE_AGENT_ACCESS_KEY,
	EMAIL_ADDRESS_EXIST,
	INPUT_EMPTY_WARNING_ID,
	MESSAGE_INVALID_EMAIL_ID,
	BTN_TXT_SAVING,
	BTN_TXT_SAVE
} from '../../common/v5/constants';
import { TXT_ACTION } from '../../common/v5/chatbotConstants'
import { DEFAULT_PAGE_SIZE, } from '../../common/v5/receiptGreetingConstants'
import {
	CELL_ACTION,
	CELL_DATE_TIME,
	NormalizedTable,
	customPageTotal
} from '../../reactcomponents/Table'
import Feature from './Feature';
import QRWrapper from  './QRCode';
import { SkillUpdateModal, AddAgentBtnStyle } from '../../views/v5/skills/Skills';
import { DeletePopup } from '../../reactcomponents/Dialog';
import CFlag from '../../reactcomponents/CFlag';

const JSONFormSubmit = ({
	accessKeyURLCreator,
	agentID,
	params,
	url,
	text
}) => (
	<JSONForm
		hasSubmit
		method='post'
		params={params}
		submitFirst
		text={text}
		url={accessKeyURLCreator({ id: agentID })}
	/>
);

const StyledDiv = styled.div`
	${flexbox}
`
const CreateAccessKeyButton = ({
	agentID,
	children,
	onCreate,
	params,
	text
}) => (
	<StyledDiv>
		<Button
			onClick={useCallbackMultiValues(onCreate, agentID, params)}
			text={text}
		/>
		{children}
	</StyledDiv>
)

const AccessKey = composeWithDisplayName(
	'AccessKey',
	withLabel,
	branch(
		({ asyncXhr }) => asyncXhr,
		renderComponent(CreateAccessKeyButton),
		omitProps(['onCreate'])
	)
)(JSONFormSubmit);

const timestampOneYearFromNow = () => moment().add({ years: 2 }).unix()

const columns = [
	{ Header: TXT_ACCESS_KEY_ID, accessor: 'access_key_id' },
	{
		Header: TXT_EXPIRY_DATE,
		accessor: 'expiry',
		type: CELL_DATE_TIME
	},
	{
		Header: TXT_ACTION,
		id: 'action',
		accessor: 'access_key_id',
		actions: [ACTION_DELETE],
		sortName: 'Action',
		type: CELL_ACTION
	}
]

const AccessKeysTable = composeWithDisplayName(
	'AccessKeysTable',
	mapProps(({ data: { byId, allIds }, ...props }) => ({
		byId,
		list: allIds,
		...props
	})),
	createWithMountCondition(({ list }) => list.length)
)(NormalizedTable);

const AgentAccessKeyBase = ({
	accessKeyURLCreator,
	agentID,
	data,
	onCreate,
	onDelete,
	onLoad,
	timezoneOffset
}) => {
	const [expiry, setExpiry] = useState(timestampOneYearFromNow)
	useEffect(() => {
		onLoad(agentID)
		return () => {
			console.log("unmount of agent access key component")
		}
	}, [agentID, onLoad])
	return (
		<AccessKey
			accessKeyURLCreator={accessKeyURLCreator}
			agentID={agentID}
			asyncXhr={USE_XHR_CREATE_AGENT_ACCESS_KEY}
			components={
				<AccessKeysTable
					columns={columns}
					data={data}
					onClickAction={useCallback((_, accessKeyID) => {
						onDelete(agentID, accessKeyID)
					}, [agentID, onDelete])}
					pageSize={DEFAULT_PAGE_SIZE}
					timezoneOffset={timezoneOffset}
					wrapperPadding='0px'
				/>
			}
			label={TXT_ACCESS_KEYS}
			onCreate={onCreate}
			params={useMemo(() => ({ expiry }), [expiry])}
			text={TXT_CREATE}
		>
			<IconTimestampPicker onChange={setExpiry} selected={expiry} />
		</AccessKey>
	)
};

const mountAccessKeyCondition = ({ agentID, isPreferences }) => (isPreferences || agentID > 0)

const AgentAccessKey = createWithMountCondition(
	mountAccessKeyCondition
)(AgentAccessKeyBase);

export class AgentPref extends React.Component {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}
	componentDidUpdate(prevProps, prevState) {
		if((prevProps.show !== this.props.show) && this.props.show){
			if(this.props.onLoad){
				this.props.onLoad(this.props.prefMode);
			}
		}
	}
	handleClose() {
		this.props.onClose();
	}
	render() {
		const p = this.props, data = p.data, input = p.input;
		let cbClass = "edit agent popup";
		let title = p.prefMode ? I("Edit my profile") : I("Edit agent");
		if(p.show) {
			cbClass = "edit agent popup open";
		}
		// TODO: this logic causes double rendering because when p.show from
		// false -> true, p.dataReady is true. Then this.props.onLoad will trigger
		// which will make p.dataReady false and thus unmount. Lastly, p.dataReady
		// is true, component mount again. Probably the better way will be avoid
		// using p.dataReady as mounting condition. It can be used as action
		// blocking but not mounting condition. Mounting condition should base on
		// whether data available or not (plus the show or not prop) is good enough.
		if(p.show && p.dataReady && input) {
			return <div className={cbClass}>
				<div className="agent-inner popup-inner create-inner">
					<div className="edit-agent-box">
						<div className="popup-tabs">
							<span className={"toggle-tabs"} >
								{title}
							</span>
						</div>
							<AgentFormCtnr
								key='agentPrefForm'
								prefMode={true}
								// this is on purpose to separate prefMode as this prop want to
								// indicate whether agent form container is used as preference
								// or not, and not to indicate whether preference popup is
								// active or not, which isn't same thing.
								isPreferences
							/>
						</div>
					<div data-qa-id="edit-agent-close" className="popup-close" onClick={this.handleClose}>
						<i className="icon-times"></i>
					</div>
				</div>
			</div>
		}else {
			return null;
		}
	}
}

function genIdNameFromNumber(num) {
	return {id: num, name: num+""};
}

const errandListSizeOption = [
	genIdNameFromNumber(10)
	, genIdNameFromNumber(15)
	, genIdNameFromNumber(17)
	, genIdNameFromNumber(25)
	, genIdNameFromNumber(35)
	, genIdNameFromNumber(50)
];

let hideQRImage = true ;

function genIdNameFromTotal(total) {
	const opt = [];
	for (let i=0; i<total; i++) {
		opt.push(genIdNameFromNumber(i));
	}
	return opt;
}

const previewDelayOpt = genIdNameFromTotal(10);

const maximumAllowedChat = [
	{id: UNSELECT, name: "0"}
	, genIdNameFromNumber(1)
	, genIdNameFromNumber(2)
	, genIdNameFromNumber(3)
	, genIdNameFromNumber(4)
	, genIdNameFromNumber(5)
	, genIdNameFromNumber(6)
	, genIdNameFromNumber(7)
	, genIdNameFromNumber(8)
	, genIdNameFromNumber(9)
	, genIdNameFromNumber(10)
	, {id: 9999, name: I('Unlimited')}
];

const labelChatbotLibraries = I('Chatbot libraries')

const ChatbotLibrariesSelect = ({ onChange, selected, ...props }) => {
	const onSelect = useCallbackWithValue(
		'automatedConversationBotLibraries',
		onChange,
		1
  )
	const [_selected, _onSelect] = useSerialToArrayInt(true, selected, onSelect);
	return (
		<LibrariesSelect
			onSelect={_onSelect}
			selected={_selected}
			title={labelChatbotLibraries}
			{...props}
		/>
	);
};

const labelAutomatedConversationBot = I('Automated Conversation Bot')

const ReadOnlyChatbot = ({ isBot }) => (
	<ReadOnlyInputRow
		label={labelAutomatedConversationBot}
		value={convertToYesOrNo(isBot)}
	/>
)

const ChatbotProviderSelectBase = ({
	onSelect,
	providers,
	selected
}) => (
	<SelectInputRow
		id="automatedConversationBotProvider"
		name="automatedConversationBotProvider"
		label={I('Provider')}
		value={selected}
		option={providers}
		onSelect={onSelect}
	/>
)

const ChatbotProviderSelect = composeWithDisplayName(
	'ChatbotProviderSelect',
	withUnmountWhenHidden,
	memo
)(ChatbotProviderSelectBase)

const ChatbotInputs = ({
	isBot,
	libraries,
	multipleChatbotProviders,
	onChangeAgentInput,
	providers,
	selectedLibraries,
	selectedProvider
}) => (
	<Fragment>
		<BooleanSelect
			name="automatedConversationBot"
			label={labelAutomatedConversationBot}
			value={isBot}
			onSelect={onChangeAgentInput}
		/>
		<ChatbotProviderSelect
			hidden={!isBot || !multipleChatbotProviders}
			onSelect={onChangeAgentInput}
			providers={providers}
			selected={selectedProvider}
		/>
		{
			selectedProvider !== 4 ? //fixme @sue to proper checking if it using meta llama bot, not fixed integer
			<ChatbotLibrariesSelect
				data={libraries}
				hidden={!isBot || !libraries || !libraries.length}
				onChange={onChangeAgentInput}
				selected={selectedLibraries}
			/> : null
		}
	</Fragment>
)

const withFetchLibraryList = Component => ({
	onLoadKnowledgeBaseList,
	...props
}) => {
	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			console.log('dbg: should only load once knowledgeBase');
		}
		onLoadKnowledgeBaseList();
	}, [onLoadKnowledgeBaseList])
	return <Component {...props} />
};

const Chatbot = composeWithDisplayName(
	'Chatbot',
	memo,
	withFetchLibraryList,
	withUnmountWhenHidden,
	branch(({ readOnly }) => readOnly, renderComponent(ReadOnlyChatbot))
)(ChatbotInputs)

const SkillTableOptions = {
	paginationSize: 3,
	pageStartIndex: 1,
	hideSizePerPage: true, // Hide the sizePerPage dropdown always
	hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
	firstPageText: '<<',
	prePageText: '<',
	nextPageText: '>',
	lastPageText: '>>',
	showTotal: true,
	paginationTotalRenderer: customPageTotal,
	disablePageTitle: true,
	sizePerPageList: [10] //todo: give user the option to choose instead of fixed value
};

const renderSkillActions = (
	cell
	, { id, skillId, proficiency }
	, { onDelete, onEdit }
) => {
	return (
		<ActionsWrapper>
			{onEdit && <Edit id={id} onClick={() => onEdit(id, skillId, proficiency)} />}
			{onDelete && <Remove
				id={id}
				onClick={(() => onDelete(id))}
			/>}
		</ActionsWrapper>
	)
}

export class AgentForm extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleTextInputChange = this.handleTextInputChange.bind(this);
		this.handleTextInputBlur = this.handleTextInputBlur.bind(this);
		this.handleCheckInput = this.handleCheckInput.bind(this);
		this.handleChangeData = this.handleChangeData.bind(this);
		this.handle2FAFlagData = this.handle2FAFlagData.bind(this);
		this.handleHotkeyChanged = this.handleHotkeyChanged.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleToggleArea = this.handleToggleArea.bind(this);
		this.handleToggleManualArea = this.handleToggleManualArea.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleSelectManualArea = this.handleSelectManualArea.bind(this);
		this.handleAvatarCrop = this.handleAvatarCrop.bind(this);
		this.handleRemoveAvatar = this.handleRemoveAvatar.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
		this.checkUsersValidity = this.checkUsersValidity.bind(this);
		this.handleSetup2FA = this.handleSetup2FA.bind(this);
		this.handleVerify2FA = this.handleVerify2FA.bind(this);
		this.openSelectSkillModal = this.openSelectSkillModal.bind(this);
		this.handleCloseSkillModal = this.handleCloseSkillModal.bind(this);
		this.handleEditAgentSkill = this.handleEditAgentSkill.bind(this);
		this.handleDeleteAgentSkill = this.handleDeleteAgentSkill.bind(this);
		this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
		this.handleCancelDelete = this.handleCancelDelete.bind(this);
		this.handleSaveSkill = this.handleSaveSkill.bind(this);

		this.state = {
			showArea: false,
			passwordWarning: "",
			nameWarning: "",
			phoneWarning: "",
			emailWarningID: 0,
			checkingLoginValidity: false,
			showManualArea : false,
			showTwoFAQR: false,
			twofaTokenVerify: false,
			twofaTokenWarning : "",
			twofaTokenValidity: false,
			openSkillModal: false,
			activeUserSkill: 0,
			activeSkillModal: {
				skill: 0,
				proficiency: 0,
				id: 0
			},
			showDeleteAlert: false,
			toBeDeleted: 0
		}
	}
	componentDidMount() {
		if(this.props.onLoadEdit) {
			this.props.onLoadEdit();
		}
	}
	componentWillUnmount() {
		if(this.props.onDismissAlert) {
			this.props.onDismissAlert();
		}
	}
	handleHotkeyChanged(field, value) {
		this.props.onChangeAgentInput(field, value, this.props.prefMode);
	}
	handleChangeData(value, field) {
		this.props.onChangeAgentInput(field, value, this.props.prefMode);
	}
	handle2FAFlagData(value, field) {
		if (this.props.prefMode){
			if (value == 1) {
				this.handleSetup2FA();
				this.setState({showTwoFAQR: true});
			} else {
				this.setState({showTwoFAQR: false});
			}
			this.props.onChangeAgentInput(field, value, this.props.prefMode);
		} else {
			if (value == 0) {
				this.props.onChangeAgentInput(field, value, this.props.prefMode);
			} else {
				alert ("2FA only can be activate by agent at User Preference page")
			}
		}
	}
	handleCheckInput(field, value) {
		if(field === "userName"){
			if(value.length === 0) {
				this.setState({nameWarning: INPUT_EMPTY_WARNING});
				return false;
			}
			this.setState({nameWarning: ""});
			
		}else if(field === "email") {
			this.props.onCheckAgentEmailAvail(this.props.activeId, value, this.props.prefMode);
			if(value.length === 0) {
				this.setState({emailWarningID: MESSAGE_INVALID_EMAIL_ID});
				return false;
			}else{
				if(isValidEmail(value)){
					this.setState({emailWarningID: 0});
					return true;
				}else{
					this.setState({emailWarningID: INPUT_EMPTY_WARNING_ID});
					return false;
				}
			}
		} else if(field === "phoneNumber") {
			if(value.length === 0) {
				this.setState({phoneWarning: INPUT_EMPTY_WARNING});
				return false;
			}else if (!isValidPhoneNo(value)) {
				this.setState({phoneWarning: MESSAGE_INVALID_PHONE_NO});
				return false;
			}
			this.setState({phoneWarning: ""});
		}
		return true;
	}
	handleTextInputBlur (e) {
		let field = e.target.id, value = e.target.value;
		this.handleCheckInput(field, value);
	}
	handleTextInputChange (e) {
		let field = e.target.id, value = e.target.value;
		if (field == "twofa_token") {
			this.setState({twofaTokenVerify: false});
		}

		this.props.onChangeAgentInput(field, value, this.props.prefMode);
	}
	checkUsersValidity(){
		let param = {
			loginname: this.props.input.loginName,
			agentId: (this.props.activeId < 1 ? 0 : this.props.activeId)
		}
		if(this.props.input.loginName.length > 0 && this.props.onValidateAgent){
			this.props.onValidateAgent(param);
		}
	}
	handleSave() {
		if(this.props.featEmailAvail !== null && this.props.featEmailAvail){
			if(!this.props.agentEmailAvailable){
				return false;
			}
		}
		this.handleVerify2FA() ;

		if ( this.props.input.twofa_flag == 1 && this.state.showTwoFAQR && (
				!this.state.twofaTokenValidity ) ) {
			alert (MESSAGE_2FA_TOKEN)
		} else {
			this.props.onSaveAgent(this.props.prefMode);
		}
	}

	handleSetup2FA() {
		this.setState({twofaTokenValidity: false});
		this.setState({showTwoFAQR: true});
		let appName = "";
		if (window.location.hostname !== "") {
			appName = "Omnichannel-" +  window.location.hostname ;
		} else {
			appName = "Omnichannel-" +  workspace ;
		}
		this.props.getTwoFANewSecret() ;
	}

	handleVerify2FA() {
		this.setState({twofaTokenVerify: true});
		if (typeof this.props.input.twofa_token == 'undefined' ) {
			this.setState({twofaTokenWarning: INPUT_EMPTY_WARNING});
			this.setState({twofaTokenValidity: false});
			return false ;
		} else {
			if (this.props.input.twofa_token.length == 0) {
				this.setState({twofaTokenWarning: INPUT_EMPTY_WARNING});
				this.setState({twofaTokenValidity: false});
				return false ;
			}else{
				this.props.onVerifyTwoFAToken(this.props.input.twofa_secret, this.props.input.twofa_token)
			}
		}
	}
	checkPassword(e) {
		let password = e.target.value, pass = true, warning = "";
		pass = isValidPassword(password, this.props.passwordMinReq);
		if(pass){
			warning = "";
		}else{
			warning = MESSAGE_INVALID_PASSWORD_FORMAT;
		}
		this.setState({
			passwordWarning: warning
		});
	}
	handleAvatarCrop(preview) {
		if(this.props.onAddAvatar){
			this.props.onAddAvatar(preview, this.props.prefMode);
		}
	}
	handleRemoveAvatar() {
		this.props.onRemoveAvatar();
	}
	handleSelectArea(val) {
		this.props.onChangeAgentInput("preferredArea", val);
	}
	handleSelectManualArea(val) {
		this.props.onChangeAgentInput("preferredManualArea", val);
	}
	handleToggleArea() {
		this.setState({
			showArea: !this.state.showArea
		});
	}
	handleToggleManualArea() {
		this.setState({
			showManualArea: !this.state.showManualArea
		});
	}
	openSelectSkillModal() {
		this.setState({openSkillModal: true});
	}
	handleCloseSkillModal() {
		this.setState({
			openSkillModal: false,
			activeUserSkill: 0,
			activeSkillModal: {
				skill: 0,
				proficiency: 0,
				id: 0
			}
		});
	}
	handleEditAgentSkill(id, skill, proficiency) {
		this.setState({
			openSkillModal: true,
			activeUserSkill: id,
			activeSkillModal: {
				skill: skill,
				proficiency: proficiency,
				id: id
			}});
	}
	handleConfirmDelete() {
		this.props.onRemoveAgentSkill(this.state.toBeDeleted);
		this.setState({showDeleteAlert: false, toBeDeleted: 0});
	}
	handleCancelDelete() {
		this.setState({showDeleteAlert: false, toBeDeleted: 0});
	}
	handleDeleteAgentSkill(id) {
		this.setState({showDeleteAlert: true, toBeDeleted: id});
		if(this.state.openSkillModal){
			this.handleCloseSkillModal();
		}
	}
	handleSaveSkill(skillId, proficiencyId) {
		const params = {
			id: this.state.activeUserSkill,
			agentId: this.props.activeId,
			skillId,
			proficiencyId
		}
		this.props.onSaveAgentSkill(params);
		this.setState({
			activeSkillModal: {
				skill: 0,
				proficiency: 0,
				id: 0
			},
			activeUserSkill: 0
		});
	}
	render() {
		const {
			accessKeyData,
			accessKeyURLCreator,
			activeUserID,
			multipleChatbotProviders,
			chatbotLibraries,
			chatbotProviders,
			isPreferences,
			onCreateAgentAccessKey,
			onDeleteAgentAccessKey,
			onLoadAgentAccessKeys,
			onLoadKnowledgeBaseList,
			timezoneOffset,
			adminStatus,
			...p
		} = this.props;
		const data = p.data, input = p.input;
		let disableSave = false, buttonSaveTxt = BTN_TXT_SAVE;
		if(adminStatus) {
			if(adminStatus.status === 1) {
				buttonSaveTxt = BTN_TXT_SAVING;
				disableSave = true;
			}
		}
		let nameWarning = "", disabled = false, avatarPreview = p.avatarPreview;
		const agentSkills = input.skills;
		const rowEvents = {
			onClick: (e, row) => {
			}
		};
		const skillCols = [
			{
				dataField: 'id',
				text: 'ID',
				sort: true,
				hidden: true
			},
			{
				dataField: 'name',
				text: I('Skill'),
				sort: true,
			},
			{
				dataField: 'proficiency',
				text: I('Proficiency'),
				sort: true,
				formatter: (cell, row) => {
					let profName = "";
					p.proficiencyList.forEach((item) => {
						if (item.id === cell) {
							profName = item.name;
						}
					});
					return profName;
				},
			},
			{
				isDummyField: true,
				dataField: 'id',
				text: I('Action'),
				headerClasses: 'center',
				formatter: (cell, row) => {
					return renderSkillActions(cell, row, actionDatas);
				},
			}
		];
		const actionDatas = {
			onEdit: this.handleEditAgentSkill
			, onDelete: this.handleDeleteAgentSkill
		}
		let allowedChatSession = input.allowedChatAmount;
		let chatMessagePreview = [{id: 0, value: I('See message')},{id: 1, value: I('Only Notification')}];
		let openExternalSystem = [{id: 0, value: I('Manual (New tab)')},{id: 1, value: I('Manual (New window)')}, {id: 2, value: I('Auto (New tab)')}, {id: 3, value: I('Auto (New window)')}];

		let passwordHelpText = I("This field is the password for the user. It will not be shown when editing.");
		passwordHelpText +="<p></p><strong>";
		passwordHelpText += I("Please Note");
		passwordHelpText += "</strong>: ";
		passwordHelpText += I("It is impossible to recover a forgotten password.");
		if(p.passwordMinReq){
			passwordHelpText += "<p>";
			passwordHelpText +=I("Minimum password requirements include");
			passwordHelpText += ":</p><p>1.  "
			passwordHelpText += I("Minimum 5 characters");
			passwordHelpText += "</p><p>2. ";
			passwordHelpText += I("At least one uppercase alphabet");
			passwordHelpText += "</p><p>3. ";
			passwordHelpText += I("At least one numeric character");
			passwordHelpText += "</p>";
		}

		let emailHelpText = I("This field is the email address for the user.<p></p>The email address is used by the system to inform the user when certain actions have been completed. For instance if the user has an export or report completed.<p></p><strong>Please Note</strong> : This email address will not be used when answering errands. To change the from address used when answering errands, go to Administration → Organizations and select an Area.");
		let extIdHelpText = I("This field tags the user with an external ID which is useful when interfacing with another system.");
		let hotkeyLabelHelpText = I("This field sets whether or not to display hotkey markers for example:")
													+ '<p></p>' + I("The underlined \"S\" in \"Send\" indicates that \"S\" is the hotkey character for the Send button.");
		let activatePreviewHelpText = I("This field sets whether or not to display previews of errands.")
							+ '<p></p>' + I("There are three options:")
							+ '<ul><li>' + I("Yes: Instantly show the preview.")
							+ '</li><li>' + I("No: Never show the preview.")
							+ '</li><li>' + I("Delay: Show the preview after a set delay.")
							+ '</li></ul>';
		const darkModeAppearanceHelperText = <div>
			<div>{I("This is to enable dark mode theming.")}</div>
			<div>{I("Use \"auto\" to folow your system's preference")}</div>
		</div>

		let allowIPHelpText = I("Administrator needs to set allowed IP addresses for the agent. Example IP address format (xxx.xxx.xxx.xxx;xxx.xxx.xxx.xxx)");
		let modalOrPref = false;
		if(p.modal || p.prefMode) {
			modalOrPref = true;
		}
		let validInputClass = "", validInputIcon = "";
		if(input.loginName !== "") {
			if(p.loginNameChecking) {
				validInputClass = "with-checking";
				validInputIcon = "fas fa-sync-alt fa-spin";
			}else{
				if(typeof p.isValidLoginName === "boolean" && p.isValidLoginName){
					validInputClass = "with-valid";
					validInputIcon = "fas fa-check";
				}else if((typeof p.isValidLoginName === "boolean")
						|| input.loginName == ""){
						validInputClass = "with-warning";
						validInputIcon = "fas fa-times";
				}
			}
		}
		let buttons = [];
		let darkModePref = false;
		if(p.prefMode){
			buttons.push(<Button key="btn-save-agent" color="blue" text={buttonSaveTxt} disabled={disableSave}
			className="save-button" onClick={this.handleSave} />);
		}
		let uploadInProgress = false;
		if(p.agentUploadInProgress == UPLOAD_AGENT_STARTED) {
			uploadInProgress = true;
		}
		let disableEditChatSess = false;
		if(!p.maxAllowedChatConfig || uploadInProgress) {
			disableEditChatSess = true;
		}

		if(!p.maxAllowedChatConfig){
			allowedChatSession = input.allowedChatAmount;
		}
		let emailWarning = "";
		if(!p.agentEmailAvailable){
			if((p.activeId == -1 || p.activeId == "") && (p.input.emailAddress == "" && p.input.email == "")){
				if(!isValidEmail(p.input.email)){
					console.info('ignoring it, as its new agent.');
				}
			}else{
				if(p.activeId != -1 && (p.input.emailAddress == "" && p.input.email == "")){
					if(!isValidEmail(p.input.email)){
						console.info('ignoring it, as its existing agent with empty email.');
					}
				}else{
					emailWarning = EMAIL_ADDRESS_EXIST;
				}
			}
		}
		if(this.state.emailWarningID === MESSAGE_INVALID_EMAIL_ID){
			emailWarning = MESSAGE_INVALID_EMAIL;
		}else if(this.state.emailWarningID === INPUT_EMPTY_WARNING_ID){
			emailWarning = INPUT_EMPTY_WARNING;
		}
		darkModePref = true;
		let hideSip = p.aventaEnabled == false;

		let appName = "";
		if (window.location.hostname !== "") {
			appName = "Omnichannel-" +  window.location.hostname ;
		} else {
			appName = "Omnichannel-" +  workspace ;
		}

		let twofa_qr = ""

		if (this.state.showTwoFAQR) {
			twofa_qr = "otpauth://totp/"+appName+":"+ input.userName +"?secret="+ input.twofa_secret +"&issuer="+appName
		}

		let twofaTokenWarning = ""
		if (this.state.showTwoFAQR && this.state.twofaTokenVerify) {
			if (typeof input.twofa_token == 'undefined' ) {
				twofaTokenWarning = INPUT_EMPTY_WARNING
			} else if (input.twofa_token.length == 0) {
				twofaTokenWarning = INPUT_EMPTY_WARNING
			} else if (input.twofaTokenValid) {
				twofaTokenWarning = ""
			} else if (!input.twofaTokenValid) {
				twofaTokenWarning = MESSAGE_2FA_TOKEN
			} else {
				twofaTokenWarning = ""
			}
			if (twofaTokenWarning == "") {
				this.setState({twofaTokenValidity: true});
			} else {
				this.setState({twofaTokenValidity: false});
			}
		}

		if(p.dataReady || p.activeId == -1) {
			return (
				<div>
				<div className="edit-agent-form">
					{p.prefMode ?
						<ReadOnlyInputRow
							label={I('Login: ')}
							value={input.loginName}
						/> :
						<TextInputRow
							id="loginName"
							name="loginName"
							className={"agent-pref-input "+validInputClass}
							label={I("Login")}
							value={input.loginName ? input.loginName : ""}
							mandatory={true}
							disabled={uploadInProgress}
							onChange={this.handleTextInputChange}
							onBlur={this.checkUsersValidity}
							textIcon={validInputIcon}
						/>
					}
					<TextInputRow
						id="password"
						name="password"
						className="agent-pref-input"
						type="password"
						mandatory={true}
						label={I("Password")}
						value={input.password ? input.password : ""}
						helperTxt={passwordHelpText}
						warning={this.state.passwordWarning}
						onChange={this.handleTextInputChange}
						onBlur={this.checkPassword}
						disabled={uploadInProgress}
					/>
					<SelectInputRow
						hide={p.prefMode ? true : false}
						id="adminLevel"
						name="adminLevel"
						className="adminLevel"
						label={I('Administration level: ')}
						option={formatValueToNameObj(p.adminLevelSrc)}
						value={input.adminLevel}
						onSelect={this.handleChangeData}
						readonly={uploadInProgress}
					/>
					<CFlag flag="2024-03-08.CEN-1919.implement.login.with.otp">
						{features["agent.otp"] && input.adminLevel==5 &&
						<TextInputRow
							id="phoneNumber"
							name="phoneNumber"
							className={"agent-pref-input "}
							label={I("Phone number")}
							value={input.phoneNumber ? input.phoneNumber : ""}
							mandatory={true}
							warning={this.state.phoneWarning}
							disabled={uploadInProgress}
							onChange={this.handleTextInputChange}
							onBlur={this.handleTextInputBlur}
							textIcon={validInputIcon}
						/>
					}
					</CFlag>
					<CheckboxInputRow
						hide={p.currentUser === 'sysadmin' ? false : true}
						id="markAsCentionUser"
						data-qa-id="markAsCentionUser"
						className="markCentionUser"
						label={I('Mark as Cention user')}
						onChange={this.handleChangeData}
						checked={input.markAsCentionUser}
					/>
					<TextInputRow
						id="userName"
						name="name"
						className="agent-pref-input"
						label={I("Name")}
						value={input.userName ? input.userName : ""}
						onChange={this.handleTextInputChange}
						mandatory={true}
						warning={this.state.nameWarning}
						onBlur={this.handleTextInputBlur}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<AvatarInputRow
						label={I('User avatar')}
						width={120}
						height={100}
						onCrop={this.handleAvatarCrop}
						onClose={this.handleAvatarClose}
						mimeTypes="image/jpeg,image/png,image/gif"
						preview={avatarPreview}
						src={""}
						className={"upload-avatar"}
						handleRemoveAvatar={this.handleRemoveAvatar}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						id="email"
						name="agentEmail"
						mandatory={true}
						warning={emailWarning}
						className="agent-pref-input"
						helperTxt={emailHelpText}
						label={I("Email")}
						value={input.email ? input.email : ""}
						onChange={this.handleTextInputChange}
						onBlur={this.handleTextInputBlur}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						id="externalID"
						name="externalId"
						className="agent-pref-input"
						label={I("External ID")}
						helperTxt={extIdHelpText}
						value={input.externalID ? input.externalID : ""}
						onChange={this.handleTextInputChange}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						hide={!features["bright-integration"]}
						id="brightAgentReferenceCode"
						name="brightRefCode"
						className="agent-pref-input"
						label={I("Bright Reference Code")}
						value={input.brightAgentReferenceCode ? input.brightAgentReferenceCode : ""}
						onChange={this.handleTextInputChange}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						hide={hideSip}
						id="sipExtensionId"
						name="sipExtensionId"
						className="agent-pref-input"
						label={I("WebRTC Extension Id")}
						value={input.sipExtensionId ? input.sipExtensionId : ""}
						onChange={this.handleTextInputChange}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						hide={hideSip}
						id="sipDeviceId"
						name="sipDeviceId"
						className="agent-pref-input"
						label={I("WebRTC Device Id")}
						value={input.sipDeviceId ? input.sipDeviceId : ""}
						onChange={this.handleTextInputChange}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						hide={hideSip}
						id="sipPassword"
						name="sipPassword"
						className="agent-pref-input"
						label={I("WebRTC password")}
						value={input.sipPassword ? input.sipPassword : ""}
						onChange={this.handleTextInputChange}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						hide={!features["solidusEnabled"]}
						id="solidusAgentID"
						name="solidusAgentId"
						className="agent-pref-input"
						label={I("Solidus Agent ID")}
						value={input.solidusAgentID ? input.solidusAgentID : ""}
						onChange={this.handleTextInputChange}
						disabled={uploadInProgress ? true : false}
					/>
					<CheckboxInputRow
						hide={!features["quicksearch.enabled"]}
						id="doQuickSearch"
						data-qa-id="doQuickSearch"
						className="agent-pref-input"
						label={I('Send Quick Search Request with Email')}
						onChange={this.handleChangeData}
						checked={input.doQuickSearch}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<TextInputRow
						hide={!features["quicksearch.enabled"]}
						id="quickSearchAgentReferenceCode"
						name="quickSearchAgentReferenceCode"
						className="agent-pref-input"
						label={I("Quick Search Reference Code")}
						value={input.quickSearchAgentReferenceCode ? input.quickSearchAgentReferenceCode : ""}
						onChange={this.handleTextInputChange}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					<SelectInputRow
						hide={!p.autoInsertAnswer}
						id="autoInsertAnswer"
						name="autoInsertPublishedAnswer"
						className="autoInsertAnswer"
						label={I("Automatically insert alternative answer")}
						yesNo={true}
						value={input.autoInsertPublishedAnswer}
						onSelect={this.handleChangeData}
					/>
					<SelectInputRow
						id="twofa_flag"
						name="twofa_flag"
						hide = {false}
						className="activate2FA"
						label={I('Activate Two-Factor Authentication (2FA)')}
						yesNo={true}
						value={input.twofa_flag ? 1 : 0}
						onSelect={this.handle2FAFlagData}
					/>

					<Button key="btn-reset-2fa`"
							hide={ !(this.props.prefMode && input.twofa_flag ? true : false) }
							color="blue"
							text={I("Setup/Reset")}
							className="btn-reset-2fa" onClick={this.handleSetup2FA} />

					<TextInputRow
						id="twofa_secret"
						name="twofa_secret"
						className="twofa_secret"
						label={I("Secret Code")}
						value={input.twofa_secret}
						onChange={this.handleTextInputChange}
						onBlur={this.handleTextInputBlur}
						disabled={true}
						hide={true}
					/>

					<div className="form-group-org" id="imgbox2"
						hidden={( this.state.showTwoFAQR && ( !input.twofa_flag ? false : true))  ? false : true } >
							<div className="col-lg-4 col-sm-4">
								<QRWrapper
									baseUrl={twofa_qr}
									download={false}
							/>
							</div>
					</div>

					<TextInputRow
						hide={( this.state.showTwoFAQR && ( !input.twofa_flag ? false : true))? false : true }
						id="twofa_token"
						name="twofa_token"
						className="class_twofa_token"
						warning={twofaTokenWarning}
						label={I("6 Digit 2FA Verification Code")}
						onChange={this.handleTextInputChange}
						onBlur={this.handleTextInputBlur}
						disabled={false}
					/>
					<Button key="btn-verify-2fa-token"
						hide={( this.state.showTwoFAQR && ( !input.twofa_flag ? false : true)) ? false : true }
						color="blue"
						text={I("Verify")}
						className="btn-verify-2fa-token" onClick={this.handleVerify2FA}
					/>

					<SelectInputRow
						id="hotkeys"
						name="hotkeys"
						className="activateHotkeys"
						label={I('Activate hotkeys')}
						yesNo={true}
						value={input.hotkeys}
						onSelect={this.handleChangeData}
					/>

					<SelectInputRow
						hide={input.hotkeys ? false : true}
						id="hotkeys_markers"
						name="hotkeys_markers"
						className="hotkeysMarkers"
						label={I('Show hotkeys markers')}
						helperTxt={hotkeyLabelHelpText}
						yesNo={true}
						value={input.hotkeys_markers}
						onSelect={this.handleChangeData}
					/>
					<div className="label-row" hidden={input.hotkeys ? false : true}>
						<div className="label-block">
							<div className={"label-"}>
								<label htmlFor={"hotkeyMods"}>{I("Choose hotkey modifiers")}</label>
							</div>
							<div className={""}>
								<NamedCheckbox label={I("Ctrl Key")} me={"hotkeys_ctrl"} onClick={this.handleHotkeyChanged} checked={input.hotkeys_ctrl} />
								<NamedCheckbox label={I("Alt Key")}  me={"hotkeys_alt"} onClick={this.handleHotkeyChanged} checked={input.hotkeys_alt} />
								<NamedCheckbox label={I("Shift Key")} me={"hotkeys_shift"} onClick={this.handleHotkeyChanged} checked={input.hotkeys_shift} />
								<NamedCheckbox label={I("⌘ Key")}  me={"hotkeys_meta"} onClick={this.handleHotkeyChanged} checked={input.hotkeys_meta} />
							</div>
						</div>
					</div>
					{p.previewFeature &&
						<SelectInputRow
							id="inboxPreview"
							name="inboxPreview"
							className="activatePreview"
							label={I('Activate preview')}
							helperTxt={activatePreviewHelpText}
							option={[{id: 0, name: I('No')},{id: 1, name: I('Yes')},{id: 2, name: I('Delay')}]}
							value={input.inboxPreview}
							onSelect={this.handleChangeData}
						/>
					}
					{p.previewFeature &&
						<SelectInputRow
							hide={input.inboxPreview == 2 ? false : true}
							id="inboxPreviewTimeDelay"
							name="inboxPreviewTimeDelay"
							className="activatePreview"
							label={I('Delay time')}
							option={previewDelayOpt}
							value={input.inboxPreviewTimeDelay}
							onSelect={this.handleChangeData}
						/>
					}
					{ p.previewSummaryFeature &&
							<CheckboxInputRow
							id="previewSummary"
							data-qa-id="previewSummary"
							className="markCentionUser"
							label={I('Show Summary in preview')}
							onChange={this.handleChangeData}
							checked={input.previewSummary}
						/>}
					<SelectInputRow
						hide={!p.showPersonalisationPreview}
						id="salutationPreview"
						name="salutationPreview"
						className="salutationPreview"
						label={I('Show salutation preview')}
						yesNo={true}
						value={input.salutationPreview}
						onSelect={this.handleChangeData}
					/>
					<SelectInputRow
						hide={!p.showPersonalisationPreview}
						id="signaturePreview"
						name="signaturePreview"
						className="signaturePreview"
						label={I('Show signature preview')}
						yesNo={true}
						value={input.signaturePreview}
						onSelect={this.handleChangeData}
					/>
					<SelectInputRow
						id="listSize"
						name="listSize"
						className="errandListSize"
						label={I('Errand List Size')}
						option={errandListSizeOption}
						value={input.listSize}
						onSelect={this.handleChangeData}
					/>
					<FormInputWithLabelRow label={I('Preferred area in All Errands')}>
						<NoFlipMultiSelect
							flip={true}
							id={"agent-areas"}
							title={I("Select areas")}
							className={"popup-multi-select"}
							overrideTitle={true}
							groupSelect={true}
							nested={{key: 'Areas'}}
							data={p.availableAreas}
							idFields={{id: "Id", name: "Name"}}
							selected={input.preferredArea}
							onSelect={this.handleSelectArea}
							onToggle={this.handleToggleArea}
							show={this.state.showArea}
							selectAll={true}
							selectNone={true}
						/>
					</FormInputWithLabelRow>
					<FormInputWithLabelRow label={I('Preferred area in Manual Errand')}>
						<SingleSelectDD
							id={"manual-errand-areas"}
							textNoItemSelected={I("Select area")}
							className={"popup-multi-select"}
							nested={{key: 'Areas'}}
							data={p.availableAreas}
							idFields={{id: "Id", name: "Name"}}
							selected={input.preferredManualArea}
							onSelect={this.handleSelectManualArea}
							onToggle={this.handleToggleManualArea}
							show={this.state.showManualArea}
						/>
					</FormInputWithLabelRow>
					<div className="label-row">
						<div className={!modalOrPref ? "label-block inline-rows" : ""}>
							<PrefSalutationSelector
								list={p.preferredSalutation}
								areaList={p.salutationAreas}
								preferredSalutationActive={input.salutation}
								onChangeSalutation={this.handleChangeData}
								canAccessAreaSettings={p.userCanAccessSysadminMenu}
								onSelectArea={p.onGetAreaSalutations}
								areaSalutations={p.areaSalutations}
								currentSelected={p.selectedAreaSalutationList}
								onAddMP={p.onAddMP}
								onDeleteMP={p.onDeleteMP}
							/>
						</div>
					</div>
					<div className="label-row">
						<div className={!modalOrPref ? "label-block inline-rows" : ""}>
							<PrefSignatureSelector
								list={p.preferredSignature}
								areaList={p.signatureAreas}
								preferredSignatureActive={input.signature}
								onChangeSignature={this.handleChangeData}
								canAccessAreaSettings={p.userCanAccessSysadminMenu}
								onSelectArea={p.onGetAreaSignatures}
								areaSignatures={p.areaSignatures}
								currentSelected={p.selectedAreaSignatureList}
								onAddMP={p.onAddMP}
								onDeleteMP={p.onDeleteMP}
								disabled={p.agentLockFieldsEdit}
							/>
						</div>
					</div>
					<TextInputRow
						id="signatureName"
						name="signatureName"
						className="agent-pref-input"
						label={I("Signature name")}
						value={input.signatureName ? input.signatureName : ""}
						onChange={this.handleTextInputChange}
						disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
					/>
					{p.chat &&
						<Fragment>
						<TextInputRow
							id="chatName"
							name="chatAgentName"
							className="agent-pref-input"
							label={I("Chat alias")}
							value={input.chatName ? input.chatName : ""}
							onChange={this.handleTextInputChange}
							disabled={(p.agentLockFieldsEdit || uploadInProgress) ? true : false}
						/>
						<SelectInputRow
							id="playSound"
							name="playSound"
							className="playChatSound"
							label={I('Play sound for new chat message')}
							yesNo={true}
							value={input.playSound ? 1 : 0}
							onSelect={this.handleChangeData}
						/>
						<SelectInputRow
							id="allowedChatAmount"
							name="allowedChatAmount"
							className="maximumAllowedChat"
							readonly={disableEditChatSess}
							label={I('Number of allowed simultaneous chat sessions')}
							option={maximumAllowedChat}
							value={!input.allowedChatAmount ? UNSELECT : allowedChatSession}
							onSelect={this.handleChangeData}
						/>
						<SelectInputRow
							id="chatMessagePreview"
							name="chatMessagePreview"
							className="chatMessagePreview"
							label={I('Chat message preview')}
							option={formatValueToNameObj(chatMessagePreview)}
							value={input.chatMessagePreview}
							onSelect={this.handleChangeData}
						/>
						</Fragment>
					}
					<SelectInputRow
						id="openExternalSystem"
						name="openExternalSystem"
						className="openExternalSystem"
						label={I('Open external system')}
						option={formatValueToNameObj(openExternalSystem)}
						value={input.openExternalSystem}
						onSelect={this.handleChangeData}
					/>
					<SelectInputRow
						id="interfaceLanguage"
						name="interfaceLanguage"
						className="interfaceLanguage"
						label={I('Preferred interface language')}
						option={formatValueToNameObj(p.interfaceLangSrc)}
						value={input.interfaceLanguage}
						onSelect={this.handleChangeData}
					/>
					<SelectInputRow
						id="spellingLanguage"
						name="spellingLanguage"
						className="spellingLanguage"
						label={I('Preferred spell check language')}
						option={formatValueToNameObj(p.langSrc)}
						value={input.spellingLanguage}
						onSelect={this.handleChangeData}
					/>
					<Feature tag="show-time-zone">
						<SelectInputRow
							id="timezoneID"
							name="timezoneID"
							className="usetimeZone"
							label={I('Time Zone')}
							option={formatValueToNameObj(p.timeZone)}
							value={input.timezoneID}
							onSelect={this.handleChangeData}
						/>
					</Feature>
					<SelectInputRow
						id="sortHistoryAscending"
						name="sortHistoryAscending"
						className="sortHistoryAscending"
						label={I('Sort History Ascending')}
						yesNo={true}
						value={input.sortHistoryAscending ? 1 : 0}
						onSelect={this.handleChangeData}
					/>
					<TextInputRow
						id="allowedIp"
						name="allowedIp"
						hide={p.prefMode ? true : false}
						className="agent-pref-input"
						label={I("Allowed IP")}
						helperTxt={allowIPHelpText}
						value={input.allowedIp ? input.allowedIp : ""}
						onChange={this.handleTextInputChange}
					/>
					<SelectInputRow
						id="blueBubbleNotification"
						name="blueBubbleNotification"
						className="blueBubbleNotification"
						label={I('Blue Bubble Notifications')}
						yesNo={true}
						value={input.blueBubbleNotification ? 1 : 0}
						onSelect={this.handleChangeData}
					/>

					<SelectInputRow
						id="browserOsNotification"
						name="browserOsNotification"
						className="browserOsNotification"
						label={I('Send Notification to OS (overrides Blue Bubble Notification if enabled)')}
						yesNo={true}
						value={input.browserOsNotification ? 1 : 0}
						onSelect={this.handleChangeData}
					/>

					<SelectInputRow
						hide={!(features["sip-enabled"] && p.prefMode)}
						id="selectedSipRingOutput"
						name="selectedSipRingOutput"
						className="selectedSipRingOutput"
						label={I('SIP Call - Select ringing device')}
						option={formatValueToNameObj(p.listOfSoundOutputDevices, "deviceId", "label")}
						value={input.selectedSipRingOutput}
						onSelect={this.handleChangeData}
					/>
					{/* {darkModePref &&
						<SelectInputRow
							id="darkMode"
							name="darkMode"
							className="darkMode"
							label={I('Dark Mode')}
							yesNo={true}
							value={input.darkMode ? 1 : 0}
							onSelect={this.handleChangeData}
						/>
					} */}
					<SelectInputRow
						id="darkModeAppearance"
						name="darkModeAppearance"
						className="darkModeAppearance"
						label={I('Dark Mode Appearance')}
						option={DARK_MODE_APPEARANCE}
						value={input.darkModeAppearance}
						onSelect={this.handleChangeData}
						helperTxt={darkModeAppearanceHelperText}
					/>

					<Chatbot
						multipleChatbotProviders={multipleChatbotProviders}
						readOnly={p.prefMode || p.activeId > 0 || p.currentLogin !== 'sysadmin'}
						hidden={!chatbotProviders || !chatbotProviders.length}
						isBot={input.automatedConversationBot}
						libraries={chatbotLibraries}
						onChangeAgentInput={this.handleChangeData}
						onLoadKnowledgeBaseList={onLoadKnowledgeBaseList}
						providers={chatbotProviders}
						selectedProvider={input.automatedConversationBotProvider}
						selectedLibraries={input.automatedConversationBotLibraries}
					/>

					{(features["machine-learning.auto-answer"] ||
						(features["amazon-comprehend"] && features["machine-learning.suggest-answer"])) && (p.currentLogin === 'sysadmin') &&
						<BooleanSelect
							name="autoAnswer"
							label={I('Automation')}
							value={input.autoAnswer}
							onSelect={this.handleChangeData}
						/>
					}
					{(features["machine-learning.auto-answer"] ||
						(features["amazon-comprehend"] && features["machine-learning.suggest-answer"])) &&
						<BooleanSelect
							name="autoPickSuggestion"
							label={I('Auto pickup suggested answer')}
							value={input.autoPickSuggestion}
							onSelect={this.handleChangeData}
						/>
					}
					<AgentAccessKey
						agentID={isPreferences ? activeUserID : p.activeId}
						accessKeyURLCreator={accessKeyURLCreator}
						data={accessKeyData}
						isPreferences={isPreferences}
						onCreate={onCreateAgentAccessKey}
						onDelete={onDeleteAgentAccessKey}
						onLoad={onLoadAgentAccessKeys}
						timezoneOffset={timezoneOffset}
					/>
					{ cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page") && p.activeId > 0 && !p.prefMode &&
						<Fragment>
							<div className="row" style={{ width: '100%' }}>
								<div className="col-11 col-md-11">
									<FormInputWithLabelRow
										label={I('Skills')}
										inlineLabel={false}
										helperTxt={I('Manage skills')}
									>
									</FormInputWithLabelRow>
								</div>
								<div className="col-1 col-md-1" style={{ display: 'flex', gap: '8px' }}>
									<div style={AddAgentBtnStyle} onClick={this.openSelectSkillModal}>
										<i style={{ color: '#6D6D6D' }} className="icon-add"></i>
									</div>
								</div>
							</div>
							<ListContentWrapper className={"v5-table-style"}>
								<BootstrapTable
									keyField="id"
									data={agentSkills}
									noDataIndication={I("No data to display")}
									columns={skillCols}
									bordered={false}
									condensed={false}
									defaultSorted={ [{
										dataField: 'name',
										order: 'asc'
									}]}
									pagination={paginationFactory(SkillTableOptions)}
									rowEvents={ rowEvents }
									hover
								/>
							</ListContentWrapper>
						</Fragment>
					}
				{
					cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page") && this.state.openSkillModal &&
					<SkillUpdateModal
						show={this.state.openSkillModal}
						isNew={this.state.activeUserSkill ? false : true}
						selectAgentMode={false}
						selectSkillMode={true}
						selectedSkill={this.state.activeSkillModal}
						proficiencyList={p.proficiencyList}
						skillList={p.skillList}
						onClose={this.handleCloseSkillModal}
						onChangeInput={this.handleChangeData}
						onSave={this.handleSaveSkill}
						onDelete={this.handleDeleteAgentSkill}
						input={input}
					/>
				}
				{	cflag.IsActive("2023-09-29.CEN-1599.skills.admin.page") &&
					<DeletePopup
						title={I("Are you sure you want to remove this skill from agent?")}
						icon={'icon-caution'}
						show={this.state.showDeleteAlert}
						onDelete={this.handleConfirmDelete}
						onClose={this.handleCancelDelete}
					/>
				}
				</div>
				<div className="label-row" style={{display: "inline-flex", float: "right"}}>
					{buttons}
				</div>
			</div>)
		}else {
			return <div className="edit-agent-form">
				<div style={{paddingTop: "50px", fontSize: "12px "}}>
					{I("Loading agents profile ...")}
				</div>
			</div>;
		}
	}
}

export class PrefSalutationSelector extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleShowAreaSal = this.handleShowAreaSal.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleSelectAreaSal = this.handleSelectAreaSal.bind(this);
		this.handleRemoveSalutationList = this.handleRemoveSalutationList.bind(this);
		this.handleAddSalutationList = this.handleAddSalutationList.bind(this);
		this.state = {
			showAreaSal: false,
			selectedArea: UNSELECT,
			selectedAreaSalutation : UNSELECT
		}
	}
	handleShowAreaSal() {
		this.setState({showAreaSal: true});
	}
	handleSelectArea(id) {
		this.setState({selectedArea: id});
		this.props.onSelectArea(id);
	}
	handleSelectAreaSal(id) {
		this.setState({selectedAreaSalutation: id});
	}
	handleRemoveSalutationList(id, type){
		this.props.onDeleteMP(id, type);
	}
	handleAddSalutationList(id, type, areaId){
		this.props.onAddMP(this.state.selectedAreaSalutation, 1, this.state.selectedArea);
	}
	render() {
		const p = this.props;
		let areaSalutations = [];
		if(p.areaSalutations.length > 0) {
			areaSalutations = p.areaSalutations;
		}else{
			areaSalutations = p.list;
		}
		return <div className="prefSalutation">
			<SelectInputRow
				id="salutation"
				name="salutation"
				className="prefSalutation"
				label={I('Preferred Salutation')}
				option={formatValueToNameObj(p.list)}
				value={p.preferredSalutationActive}
				onSelect={this.props.onChangeSalutation}
				selectNone={true}
			/>
			<Button hide={!p.canAccessAreaSettings} key="btn-add-area-sal" color="blue" text={I("Add area specific salutation")} className="btn-agent-inner" onClick={this.handleShowAreaSal} />
			<div className="sub-label-row">
				<SelectInputRow
					hide={!this.state.showAreaSal}
					id="areaSalutation"
					name="areaSalutation"
					className="areaSalutation"
					label={I('Choose Area')}
					option={p.areaList}
					value={this.state.selectedArea}
					onSelect={this.handleSelectArea}
				/>
				<SelectInputRow
					hide={this.state.selectedArea > 0 ? false : true}
					id="prefAreaSalutation"
					name="prefAreaSalutation"
					className="prefAreaSalutation"
					label={I('Choose Salutation')}
					option={formatValueToNameObj(areaSalutations)}
					value={this.state.selectedAreaSalutation}
					onSelect={this.handleSelectAreaSal}
				/>
				<ShowPersonalisationList
					hideRemoveBtn={!p.canAccessAreaSettings}
					list={p.currentSelected}
					type={1} hideAddBtn={this.state.selectedArea > 0 ? false : true}
					onAddToPersonalisation={this.handleAddSalutationList}
					onRemovePersonalisation={this.handleRemoveSalutationList}
				/>
			</div>
		</div>
	}
}

export class PrefSignatureSelector extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleShowAreaSig = this.handleShowAreaSig.bind(this);
		this.handleSelectArea = this.handleSelectArea.bind(this);
		this.handleSelectAreaSig = this.handleSelectAreaSig.bind(this);
		this.handleRemoveSignatureList = this.handleRemoveSignatureList.bind(this);
		this.handleAddSignatureList = this.handleAddSignatureList.bind(this);
		this.state = {
			showAreaSig: false,
			selectedArea: UNSELECT,
			selectedAreaSignature: UNSELECT
		}
	}
	handleRemoveSignatureList(id, type){
		this.props.onDeleteMP(id, type);
	}
	handleAddSignatureList(id, type, areaId){
		this.props.onAddMP(this.state.selectedAreaSignature, 2, this.state.selectedArea);
	}
	handleShowAreaSig() {
		this.setState({showAreaSig: true});
	}
	handleSelectArea(id) {
		this.setState({selectedArea: id});
		this.props.onSelectArea(id);
	}
	handleSelectAreaSig(id) {
		this.setState({selectedAreaSignature: id});
	}
	render() {
		const p = this.props;
		let areaSignatures = [];
		if(p.areaSignatures.length > 0) {
			areaSignatures = p.areaSignatures;
		}else{
			areaSignatures = p.list;
		}
		let opt = {};
		if (p.disabled) {
			opt["disabled"] = "disabled";
		}
		return <div className="prefSignature">
			<SelectInputRow
				id="signature"
				name="signature"
				className="prefSignature"
				label={I('Preferred Signature')}
				option={formatValueToNameObj(this.props.list)}
				value={p.preferredSignatureActive}
				onSelect={this.props.onChangeSignature}
				readonly={p.disabled}
				selectNone={true}
			/>
			<Button hide={!p.canAccessAreaSettings} key="btn-add-area-sig" color="blue" text={I("Add area specific signature")} className="btn-agent-inner" onClick={this.handleShowAreaSig} {...opt}/>
			<div className="sub-label-row">
				<SelectInputRow
					hide={!this.state.showAreaSig}
					id="areaSignature"
					name="areaSignature"
					className="areaSignature"
					label={I('Choose Area')}
					option={this.props.areaList}
					value={this.state.selectedArea}
					onSelect={this.handleSelectArea}
					readonly={p.disabled}
				/>
				<SelectInputRow
					hide={this.state.selectedArea > 0 ? false : true}
					id="prefAreaSignature"
					name="prefAreaSignature"
					className="prefAreaSignature"
					label={I('Choose Signature')}
					option={formatValueToNameObj(areaSignatures)}
					value={this.state.selectedAreaSignature}
					onSelect={this.handleSelectAreaSig}
					readonly={p.disabled}
				/>
				<ShowPersonalisationList list={p.currentSelected}
					hideRemoveBtn={!p.canAccessAreaSettings}
					type={2} hideAddBtn={this.state.selectedArea > 0 ? false : true}
					onAddToPersonalisation={this.handleAddSignatureList}
					onRemovePersonalisation={this.handleRemoveSignatureList}
					disabled={p.disabled}
				/>
			</div>
		</div>
	}
}

export class ShowPersonalisationList extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	handleRemoveItem(mid, type) {
		this.props.onRemovePersonalisation(mid, type);
	}
	shortenText(name, limit) {
		var shortenTxt = name;
		if(name.length > limit)
			shortenTxt = name.substring(0, (limit-3))+"...";
		return shortenTxt;
	}
	render() {
		const p = this.props;
		let areaMPList = [], removeTitle = I("Remove"), disableTitle;
		if(p.hideRemoveBtn){
			disableTitle = I("Only administrator can remove.");
		}
		let opt = {};
		if (p.disabled) {
			opt["disabled"] = "disabled";
		}

		let addButton = <Button key={"btn-add-to-mp"} hide={p.hideAddBtn} data-qa-id={"btn-add-to-mp"}
				color="blue" text={"+"} style={{padding: "5px 10px", float: "right", fontSize: "12px"}}
				onClick={p.onAddToPersonalisation} {...opt}/>

		if(p.list && p.list.length > 0) {
			for(let i=0; i<this.props.list.length; i++) {
				let mps = this.props.list[i];
				let mpName = mps.personalisationName;

				let deleteButton = <Button key={"btn-remove-mp-"+i} data-qa-id={"btn-remove-mp-"+i}
										disabled={disableTitle}
										title={removeTitle}
										color="red" text={"x"} style={{padding: "5px 10px"}}
										onClick={this.handleRemoveItem.bind(this, mps.id, this.props.type)} {...opt}/>
				if( !mps.removable ){
					mpName = I("{PNAME} - (Global default)").replace('{PNAME}', mps.personalisationName);
					deleteButton = "";
				}
				areaMPList.push(<tr id={"mpSpecialisations_" + mps.id} key={mps.id}>
									<td title={mps.areaName}>{this.shortenText(mps.areaName,30)}</td>
									<td title={mpName}>{this.shortenText(mpName,50)}</td>
									<td className="alignRight">{deleteButton}</td>
								</tr>)
			}
			return (
			<div className="selectedContainer col-lg-12 col-md-8">
				{addButton}
				<table id={"mpsTable_"+ this.props.type} className="table">
					<thead>
						<tr>
							<th>{I("Area")}</th>
							<th>{(this.props.type == 1 ? I("Salutation") : I("Signature"))}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{areaMPList}
					</tbody>
				</table>
			</div>
			)
		}else{
			return <span></span>
		}
	}
}

class AgentList extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div>agent list</div>
		)
	}
}

class Agent extends React.Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		if(this.props.onLoad){
			this.props.onLoad(this.props);
		}
	}
	render() {
		const p = this.props;
		return <AgentList />
	}
}

export default Agent;
