import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
	AT_ARCHIVE,
	AT_BROWSE,
	AT_COL_ANS,
	AT_QUESTION,
	AT_SAVED,
	AT_LIBRARY,
	AT_UPLOADED,
	EXT_EXPERT_EDIT_ATTACHMENT_TYPE,
	TITLE_ATTACHED_FILES,
	TITLE_COLLABORATE_FILES,
	RPLY_EXT_FWD,
	ECB_INC_HISTORIES,
	ECB_FWD_ALL_HISTORIES,
	RPLY_ERRAND,
	IS_HISTORY_ATTACHMENT,
	RPLY_COLLABORATE,
	RPLY_COMMENT
} from '../../common/v5/constants';
import {
	I,
	webRoot
} from '../../common/v5/config.js';
import {
	filePathPrefix,
	humanByteSize,
	isImageFilename
} from '../../common/v5/helpers.js';
import FilePreview, { FilePreviewV5 } from './FilePreview';
import ToggleSwitch from './ToggleSwitch';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import SidebarSettingsList from "../../reactcomponents/SidebarSettingsList";
import { ButtonIcon } from '../../reactcomponents/HeaderActions';
import classNames from 'classnames';
import { OneNavItem } from "../../reactcomponents/NavTabs";
import {
	TabContent
	, TabPane
	, Nav
} from 'reactstrap';
import { withUnmountWhenHidden } from "../../reactcomponents/hocs";
import { PortalModalBox } from "../../reactcomponents/Modal";
import FileUploader, { FileUploadDropArea } from "./FileUploader";

const atType = {
	agent: {
		mainClass: 'errand-box-attachments-agent-single-item',
		actionIcon: 'fa fa-times fa-1x',
		iconTitle: I('Remove'),
		descClass: 'agent-item-image-desc'
	},
	one: {
		mainClass: "errand-box-attachments-single-item",
		actionIcon: 'fa fa-check-circle fa-1x',
		iconTitle: I('Select'),
		descClass: 'item-image-desc'
	},
	oneA: {
		mainClass: "errand-box-attachments-single-item-answer",
		actionIcon: 'fa fa-check-circle fa-1x',
		iconTitle: I('Select'),
		descClass: 'item-image-desc'
	},
	oneQ: {
		mainClass: "errand-box-attachments-single-item",
		actionIcon: 'fa fa-plus-square fa-1x',
		iconTitle: I('Insert'),
		descClass: 'item-image-desc'
	},
	genericAdd: {
		mainClass: "errand-box-attachments-single-item",
		actionIcon: 'fa fa-plus-square fa-1x',
		iconTitle: I('Insert'),
		descClass: 'item-image-desc'
	}
};

class ImageThumbnail extends React.PureComponent {
	constructor(props) {
		super(props);
		this.displayName = "ImageThumbnail";
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		if(!this.props.removed) {
			this.props.onClick(true, this.props.fileName, this.props.type, e);
		}
	}
	render() {
		return (
			<i className="file-thumbnail"
				aria-hidden='true' onClick={this.handleClick}>
				<LazyLoadImage src={this.props.src}
					title={this.props.fileName}
					alt={this.props.fileName}
					delayTime="300"
					delayMethod="throttle"
				/>
			</i>
		);
	}
}

class IconThumbnail extends React.PureComponent {
	constructor(props) {
		super(props);
		this.displayName = "Thumbnail";
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		if(!this.props.removed) {
			this.props.onClick(false, this.props.src, this.props.type, e);
		}
	}
	render() {
		return <i className={this.props.icon+' file-thumbnail'}
			aria-hidden='true' onClick={this.handleClick} />;
	}
}

const TextThumbnail = ({
		title,
		filesRemoved,
		text,
		onClick
	}) => {
	return (
		<div className="errand-box-attachments-single-item">
			<div className={classNames("item-image", {removed: filesRemoved})}
				title={title}>
				<i className="thumbnail-placeholder" onClick={onClick}>{text}</i>
			</div>
		</div>
	)
}

const Thumbnail = ({ isImage, icon, ...props }) => {
	if(isImage) {
		return  <ImageThumbnail {...props} />;
	} else {
		return  <IconThumbnail icon={icon} {...props} />;
	}
};

function fileNameToIcon(fileName) {
	const image = isImageFilename(fileName);
	let icon = 'fa fa-paperclip', type;
	if(fileName && image) {
		type = "img";
	} else {
		let ext;
		if(fileName) {
			ext = fileName.substr(fileName.indexOf('.') + 1);
			type = ext;
		} else {
			type = '';
		}
		if(ext === 'pdf') {
			icon = 'fa fa-file-pdf';
		} else if(ext === 'docx' || ext === 'doc') {
			icon = 'fa fa-file-word';
		} else if(ext === 'xlsx' || ext === 'csv') {
			icon = 'fa fa-file-excel';
		} else if(ext === 'mp3') {
			icon = 'fa fa-file-audio';
		} else if(ext === 'mp4' || ext === 'webm') {
			icon = 'fa fa-file-video';
		}
	}
	return {icon, type, image};
}

function hasQueryParams(url) {
	if (typeof url === 'string') {
		return url.includes('?');
	} else {
		return false;
	}
}
function hasQueryViewParams(url) {
	return url.includes('q=view');
}
class RawAttachmentBox extends React.PureComponent {
	constructor(props) {
		super(props);
		this.displayName = "RawAttachmentBox";
		this.state = {
			preview: false
		};
		// this.handleClick = this.handleClick.bind(this);
		this.handleIconClick = this.handleIconClick.bind(this);
		this.openPreview = this.openPreview.bind(this);
		this.closePreview = this.closePreview.bind(this);
	}
	// handleClick() {
	// 	if(this.props.atType === 'one') {
	// 		this.props.onSelect(this.props.me, !this.props.selected);
	// 	}
	// }
	handleIconClick(e) {
		if(!this.props.selectable) {
			return;
		} else if(this.props.atType === 'agent') {
			this.props.onSelectDelete(this.props.me, this.props.type,
				this.props.currentReply, this.props.fileName);
		} else if(this.props.atType === 'one' || this.props.atType === 'oneQ' || this.props.atType === 'oneA') {
			this.props.onSelect(this.props.me, !this.props.selected,
				this.props.isHistoryAttachment);
		} else if(this.props.atType === 'genericAdd') {
			this.props.onSelect()
		}
	}
	openPreview(isImage, file, type, e) {
		e.preventDefault();
		if (!isImage &&
			typeof this.props.onGetAttachmentContent == 'function') {
			this.props.onGetAttachmentContent(file, type);
		}
		this.setState({preview: true});
	}
	closePreview() {
		this.setState({preview: false});
	}
	render() {
		const {
			atType: typ,
			createdAt,
			download,
			sizeHuman,
			estSize,
			fileName,
			filesRemoved,
			id,
			selected,
			selectable,
			src,
			textFilePreview,
			csvFilePreview,
			attachmentStatus
		} = this.props, {
			mainClass,
			actionIcon,
			iconTitle,
			descClass
		} = atType[typ];
		let iconCheckClass = 'checkbox', createTime = null, previewType='',
			fileExtension='', fileSize='',
			fileIconClass = 'fa fa-paperclip fa-2x';
		if(selected) {
			iconCheckClass += ' checkbox-checked';
		}
		if(createdAt) {
			createTime = <div>{createdAt}</div>;
		}
		if(fileName){
			fileExtension = fileName.substr( fileName.indexOf('.') + 1 );
		}
		const isImage = isImageFilename(fileName);
		if(filesRemoved) {
			//file no longer exist
			previewType = "invalid";
			fileIconClass = 'icon-file-removed';
		} else {
			if(fileName && isImage) {
				//using image thumbnail
				previewType = "img";
			}else{
				//using icon as representation
				previewType = fileExtension;
				if(fileExtension === 'pdf'){
					fileIconClass = 'fa fa-file-pdf fa-2x';
				}else if(fileExtension === 'docx' || fileExtension === 'doc'){
					fileIconClass = 'fa fa-file-word fa-2x';
				}else if(fileExtension === 'xlsx' || fileExtension === 'csv'){
					fileIconClass = 'fa fa-file-excel fa-2x';
				}else if(fileExtension === 'mp3'){
					fileIconClass = 'fa fa-file-audio fa-2x';
				}else if(fileExtension === 'mp4' || fileExtension === 'webm'){
					fileIconClass = 'fa fa-file-video fa-2x';
				}
			}
		}
		let hideSelectCb = !selectable;
		if(filesRemoved) {
			fileSize = <div className="label-filesize" style={{color: "#969696"}}>{I("Deleted")}</div>
			hideSelectCb = true;
		}else {
			if(sizeHuman){
				fileSize = <div className="label-filesize">{sizeHuman}</div>;
			}else{
				fileSize = <div className="label-filesize">{humanByteSize(estSize)}</div>
			}
		}
		let viewLink = download;
		if(hasQueryParams(download)) {
			if(!hasQueryViewParams(download)) {
				viewLink = download + "&q=view";
			}
		} else {
			viewLink = download + "?q=view";
		}
		const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");
		if (cflagAttachment){
			return <ThumbnailWithAction
						mainClass={mainClass}
						checkBoxClass={iconCheckClass}
						hideCheckbox={hideSelectCb}
						onClickCheckbox={this.handleIconClick}
						actionIconClass={actionIcon}
						actionTitle={iconTitle}
						filesRemoved={filesRemoved}
						attachmentStatus={attachmentStatus}
						isImage={isImage && !filesRemoved}
						fileIconClass={fileIconClass}
						src={src}
						onClickPreview={this.openPreview}
						itemDescClass={descClass}
						fileName={fileName}
						viewLink={viewLink}
						fileSize={fileSize}
						createTime={createTime}
						previewType={previewType}
						downloadSrc={download}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						isPreview={this.state.preview}
						onClosePreview={this.closePreview}
					/>
		} else {
		return <div className={mainClass}>
				<div className={iconCheckClass} hidden={hideSelectCb}
					onClick={this.handleIconClick}>
					<i className={actionIcon} aria-hidden="true"
						title={iconTitle} />
				</div>
				<div
					className={classNames("item-image", {removed: filesRemoved})}
					title={attachmentStatus}>
					<Thumbnail
						isImage={isImage && !filesRemoved}
						icon={fileIconClass}
						src={src}
						type={previewType}
						removed={filesRemoved}
						onClick={this.openPreview}
					/>
				</div>
				<div className={descClass} title={fileName}>
					<span className={classNames("item-image-filename", {removed: filesRemoved})}>
						{!filesRemoved ?
							<a href={viewLink} target="_blank">{fileName}</a>
							:<span>{fileName}</span>
						}
					</span>
					{fileSize}
					{createTime}
				</div>
				<FilePreview
					type={previewType}
					src={src}
					downloadSrc={download}
					fileName={fileName}
					textFilePreview={textFilePreview}
					csvFilePreview={csvFilePreview}
					open={this.state.preview}
					onClose={this.closePreview}
				/>
			</div>;
		}
	}
}

export const ThumbnailWithAction = ({
	mainClass
	, checkBoxClass
	, hideCheckbox
	, onClickCheckbox
	, actionIconClass
	, actionTitle
	, filesRemoved
	, attachmentStatus
	, isImage
	, fileIconClass
	, src
	, onClickPreview
	, itemDescClass
	, fileName
	, viewLink
	, fileSize
	, createTime
	, previewType
	, downloadSrc
	, textFilePreview
	, csvFilePreview
	, isPreview
	, onClosePreview
}) => {
	return (
		<div className={mainClass}>
			<div className={checkBoxClass} hidden={hideCheckbox}
				onClick={onClickCheckbox}>
				<i className={actionIconClass} aria-hidden="true"
					title={actionTitle} />
			</div>
			<div
				className={classNames("item-image", {removed: filesRemoved})}
				title={attachmentStatus}>
				<Thumbnail
					isImage={isImage}
					icon={fileIconClass}
					src={src}
					type={previewType}
					removed={filesRemoved}
					onClick={onClickPreview}
				/>
			</div>
			<div className={itemDescClass} title={fileName}>
				<span className={classNames("item-image-filename", {removed: filesRemoved})}>
					{!filesRemoved ?
						<a href={viewLink} target="_blank">{fileName}</a>
						:<span>{fileName}</span>
					}
				</span>
				{fileSize}
				{createTime}
			</div>
			<PortalModalBox
				show={isPreview}
				className={"attachment-preview"}
				headerContent={<div className='title'>{fileName}</div>}
				onClose={onClosePreview}
				withOutsideClick={true}
			>
				<FilePreviewV5 
					type={previewType}
					src={src}
					downloadSrc={downloadSrc}
					fileName={fileName}
					textFilePreview={textFilePreview}
					csvFilePreview={csvFilePreview}
				/>
			</PortalModalBox>
		</div>
	)
}

const OneAttachmentBox = ({...props}) => <RawAttachmentBox atType="one"
	{...props} />;

const OneAnswerAttachmentBox = ({...props}) => <RawAttachmentBox atType="oneA"
	{...props} />;

const OneQuestionAttachment = ({selected, ...props}) => <RawAttachmentBox atType="oneQ" selected={true}
	{...props} />;

const DeletableAttachment = ({type, selected, selectable, ...props}) =>
	<RawAttachmentBox atType="agent" selected={true} selectable={true} type={type}
	{...props} />;

const InsertedOneQuestionAttachment = ({...props}) => <DeletableAttachment
	type={AT_QUESTION} {...props} />;

const CollaborateAnsAttachment = ({...props}) => <DeletableAttachment
	type={AT_COL_ANS} {...props} />;

export const AgentSingleAttachment = ({value, download, ...props}) => <RawAttachmentBox
	download={download} src={download} fileName={value} selectable={true}
	selected={true} atType="agent" {...props} />;

const Group = ({title, children}) => <div className="attachment-row-group">
		<div className="title">{title}</div>
		{children}
	</div>;

class GroupAttachments extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			visibleData: 5, //Initially display the first 5 objects.
		}
	}

	loadMore = () => {
		// Increase the number of visible objects by 5
		this.setState((prevState) => ({
			visibleData: prevState.visibleData + 5,
		}));
	};

	render() {
		if(!this.props.list || !this.props.list.length) {
			return null;
		}
		const {
			list,
			onSelect,
			selectedList,
			title,
			textFilePreview,
			csvFilePreview,
			onSetTextFilePreview,
			onGetAttachmentContent,
			isHistoryAttachment
		} = this.props;
		const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");

		if(cflagAttachment){
			const remainingItems = list.length - this.state.visibleData;
			const attachments = list.slice(0, this.state.visibleData).map(item => {
				const id = item.id, {...props} = item;
				let selectable = cflag.IsActive("2024-03-26.CEN-2280.exclude.unselected.inline.image") ?
					(item.hasOwnProperty('embeddedImage') === false || item.embeddedImage === false) : true;
				return (!item.outgoingAttachment) ? <OneAttachmentBox key={id} me={id}
						selectable={selectable}
						selected={selectedList[id]}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						onGetAttachmentContent={onGetAttachmentContent}
						attachmentStatus={I("Incoming attachment")}
						isHistoryAttachment={isHistoryAttachment}
						onSelect={onSelect} {...props}
					/> :
					<OneAnswerAttachmentBox key={id} me={id}
						selectable={selectable}
						selected={selectedList[id]}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						onGetAttachmentContent={onGetAttachmentContent}
						attachmentStatus={I("Outgoing attachment")}
						isHistoryAttachment={isHistoryAttachment}
						onSelect={onSelect} {...props}
					/>;
			});

			const loadMoreText = <div className="thumbnail-text" style={{display: "flex", flexDirection: "column"}}>
				<span>+ {remainingItems}</span>
				<span>more</span>
			</div>
			return <Group title={title}>
						{attachments}
						{this.state.visibleData < list.length && (
							<TextThumbnail
								title={"load more"}
								text={loadMoreText}
								onClick={this.loadMore}
							/>
						)}
					</Group>
		} else{
			const attachments = list.map(item => {
				const id = item.id, {...props} = item;
				let selectable = cflag.IsActive("2024-03-26.CEN-2280.exclude.unselected.inline.image") ?
					(item.hasOwnProperty('embeddedImage') === false || item.embeddedImage === false) : true;
				return (!item.outgoingAttachment) ? <OneAttachmentBox key={id} me={id}
					selectable={selectable}
					selected={selectedList[id]}
					textFilePreview={textFilePreview}
					csvFilePreview={csvFilePreview}
					onGetAttachmentContent={onGetAttachmentContent}
					attachmentStatus={I("Incoming attachment")}
					isHistoryAttachment={isHistoryAttachment}
					onSelect={onSelect} {...props} /> :
					<OneAnswerAttachmentBox key={id} me={id}
						selectable={selectable}
						selected={selectedList[id]}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						onGetAttachmentContent={onGetAttachmentContent}
						attachmentStatus={I("Outgoing attachment")}
						isHistoryAttachment={isHistoryAttachment}
						onSelect={onSelect} {...props} />;
			});
		return <Group title={title}>{attachments}</Group>
		}
	}
}

const archiveAttachmentsText = I("Archive"),
	libraryAttachmentsText = I("Knowledge base attachments"),
	savedAttachmentsText = I("Saved"),
	insertedQAttachmentsText = I("Conversation"),
	insertedColAnsAttachText = I("Collaboration");

const NoFilesThumbnail = ({ label }) =>
		<div className="no-files-placeholder">
			<div className="image-container">
				<i className="icon-image"></i>
				<i className="icon-file"></i>
			</div>
			<div className="label">{label}</div>
		</div>

const AttachmentOptionsDD = ({
	show,
	hidden, //maybe not needed ( can hide from parent)
	onDelete,
	downloadSrc
}) => {
	const [openDD, setOpenDD]= useState(show);

	return (
		<div id="attachment-messagebox" className="col-sm-3 content-right info-options"
			hidden={hidden}>
			<ButtonIcon
				className={classNames("messagebox__toggler btn-transparent", {"active" : openDD})}
				icon={"icon-spread"}
				onClick={() => setOpenDD(!openDD)}
				title={I("Toggle attachment options")}
				noTooltip={true}
				data-qa-id={"btn-attachment-messagebox"}
			/>
			<ul className='messagebox__panel' hidden={!openDD}>
				<li className="messagebox__item"><a href={downloadSrc}>{I('Download All')}</a></li>
				<li className="messagebox__item"><a href="#" onClick={onDelete}>{I('Delete All')}</a></li>
			</ul>
		</div>
	)
}

// Custom hook to handle the "Load More" functionality
function useLoadMore(list, initialVisible = 2, increment = 2) {
	const [visibleData, setVisibleData] = useState(initialVisible);

	const loadMore = () => {
		setVisibleData((prevVisibleData) => prevVisibleData + increment);
	};

	const hasMore = list ? visibleData < list.length : false;

	return { visibleData, loadMore, hasMore: hasMore };
}

const LoadMoreThumbnails = ({ data, itemMapper, initialVisible, incrementSize }) => {
	const { visibleData, loadMore, hasMore } = useLoadMore(data, initialVisible, incrementSize);

	if (!data || data.length === 0) {
		return <NoFilesThumbnail label={I("No files available")} />;
	}

	// map data items to "jsx component" here (instead of parent) to save performance
	const itemsToRender = data.slice(0, visibleData).map(itemMapper);

	const remainingItems = data.length - visibleData;

	const loadMoreText = (
		<div className="thumbnail-text" style={{ display: "flex", flexDirection: "column" }}>
			<span>+ {remainingItems}</span>
			<span>{I("more")}</span>
		</div>
	);

	return (
		<div className="thumbnail-gallery">
			{itemsToRender}
			{hasMore && <TextThumbnail title={"load more"} text={loadMoreText} onClick={loadMore} />}
		</div>
	);
};

const ImageArchiveThumbnails = ({ data, onSelectItem }) => {

	// function that maps each array elements to a component
	const itemMapper = (item) => (
		<RawAttachmentBox
			atType="genericAdd"
			key={item.id}
			selected={true}
			selectable={true}
			onSelect={() => onSelectItem(item.id)}
			mainClass={"errand-box-attachments-single-item"}
			src={item.src}
			fileName={item.name}
			{...item}
		/>
	);

	return <LoadMoreThumbnails
				data={data}
				itemMapper={itemMapper}
				initialVisible={5}
				incrementSize={5}
			/>;
 };

const CollaborationThumbnails = ({ data, onSelectItem }) => {

	// function that maps each array elements to a component
	const itemMapper = (item) => (
		<RawAttachmentBox
			key={item.id}
			atType="genericAdd"
			selected={true}
			selectable={true}
			onSelect={() => onSelectItem(item)}
			{...item}
		/>
	);

	return <LoadMoreThumbnails
				data={data}
				itemMapper={itemMapper}
				initialVisible={5}
				incrementSize={5}
			/>;
};


const AttachmentTabsBase = ({
	errandsPanel
	, archivePanel
	, collaborationPanel
	, currentReply
	, ...props
}) => {
	const TAB_CONVERSATION = 1
		, TAB_ARCHIVE = 2
		, TAB_COLLABORATION = 3
		, TAB_LINKED = 4

	const [activeTab, setActiveTab] = useState(TAB_CONVERSATION);
	// const [showA, setShowA] = useState(false);

	useEffect(()=> {
		// avoid TAB_CONVERSATION to be selected by default if on ForwardToExternal since it'll be empty.
		if(currentReply === RPLY_EXT_FWD || props.chat) {
			setActiveTab(TAB_ARCHIVE)
		} else {
			setActiveTab(TAB_CONVERSATION)
		}
	}, [currentReply])
	return (
		<section className={"attachment-tabs"}>
			<Nav tabs>
				<OneNavItem
					active={activeTab === TAB_CONVERSATION}
					onClick={(v) => {setActiveTab(v)}}
					tabId={TAB_CONVERSATION}
					text={I("This conversation")}
					title={I("This conversation")}
					hidden={currentReply === RPLY_EXT_FWD || props.chat}
					// disabled={props.isNew}
					// hidden={hideNoteTab}
				/>
				<OneNavItem
					active={activeTab === TAB_ARCHIVE}
					onClick={(v) => {setActiveTab(v)}}
					tabId={TAB_ARCHIVE}
					text={I("Image archive")}
					title={I("Image archive")}
				/>
				<OneNavItem
					active={activeTab === TAB_COLLABORATION}
					onClick={(v) => {setActiveTab(v)}}
					tabId={TAB_COLLABORATION}
					text={I("Collaboration answers")}
					title={I("Collaboration answers")}
					hidden={currentReply === RPLY_COLLABORATE || props.chat}
				/>
			</Nav>
			<TabContent activeTab={activeTab}>
				{/* Panel 1 */}
				<TabPane tabId={TAB_CONVERSATION}>
					{/* <ReplyAttachmentBox show={true} /> */}
					{errandsPanel}
				</TabPane>
				{/* Panel 2 */}
				<TabPane tabId={TAB_ARCHIVE}>
					{archivePanel}
				</TabPane>
				{/* Panel 3 */}
				<TabPane tabId={TAB_COLLABORATION}>
					{collaborationPanel}
				</TabPane>
			</TabContent>
		</section>
	);
}

const AttachmentTabs = withUnmountWhenHidden(AttachmentTabsBase)
export class AttachmentBox extends React.PureComponent {
	constructor(props) {
		super(props);
		this.closeAttDownloadBox = this.closeAttDownloadBox.bind(this);
		this.openAttDownloadBox = this.openAttDownloadBox.bind(this);
		this.handleCheckAllAttachment = this.handleCheckAllAttachment.bind(this);
		this.state = {
			showAttDownloadBox: true
		};
	}
	closeAttDownloadBox = () => {
		this.setState({showAttDownloadBox: false});
	}
	openAttDownloadBox = () => {
		this.setState({showAttDownloadBox: true});
	}
	handleDeleteAllAttachs = () => {
		this.props.deleteAllAgentAttachment(this.props.errand.id);
	}
	handleCheckAllAttachment = (value) => {
		this.props.onCheckAllAttachment(value);
	}
	handleToggleAllAttachemnt = () => {
		this.handleCheckAllAttachment(!this.props.allAttachmentChecked)
	}
	render() {
		if(!this.props.show) {
			return null;
		}
		const {
			currentReply,
			errand,
			isCollaboration,
			collaborationAttachments,
			onInsertCollabAnswerAttachment,
			list,
			onSelect,
			canInsertIncomingAttachments,
			selectedList,
			size,
			collaborateAnsAttachments,
			savedAttachments,
			questionAttachments,
			uploadedAttachments,
			onDeleteAttch,
			archiveFiles,
			libraryFiles,
			textFilePreview,
			csvFilePreview,
			onGetAttachmentContent,
			includeHistory,
			extForwardHistory,
			allAttachmentChecked,
			historyAttachmentList,
			archiveImgs,
			onSelectArchives,
			chat,
			attachFile,
			isUploading,
			selectedHistoryAttachmentList
		} = this.props;
		const cflagAttachment = cflag.IsActive("2023-08-31.CEN-1440.new.errand.attachment.design");
		let attachments
			, attachmentsToForward
			, attachmentsClass = 'attachment-row'
			, selectedStatus = null
			, hideDownloadBox = false
			, title
			, agentAttchs = null
			, uploadAttachmentText
			, agentAttchCount = null
			, archiveAttachments = null
			, archiveAttchCount = null
			, libraryAttachments = null
			, libraryAttchCount = null
			, savedAttachment = null
			, savedAttachCount = null
			, qAttachment = null
			, qAttachCount = null
			, colAnsAttach = null
			, colAnsAttachCount = null
			, isForward = currentReply === RPLY_EXT_FWD
			, isReplyErrand = currentReply === RPLY_ERRAND
			, historyAttachments
			, selectAllCheckbox = null;
		if(isCollaboration) {
			uploadAttachmentText = I("Uploaded attachments");
			if (savedAttachments.length) {
				savedAttachment = savedAttachments.map(item => {
					const id = item.id, {...props} = item;
					return <AgentSingleAttachment key={id} me={id} {...props}
						currentReply={currentReply}
						onSelectDelete={onDeleteAttch} type={AT_SAVED}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						onGetAttachmentContent={onGetAttachmentContent} />;
				});
				savedAttachCount = <span> | {savedAttachmentsText + ':'} {savedAttachments.length}</span>;
			}
		} else {
			uploadAttachmentText = I("Uploaded");
			if(savedAttachments.length) {
				savedAttachment = savedAttachments.map(item => {
					const id = item.id, {...props} = item;
					return <AgentSingleAttachment key={id} me={id} {...props}
						currentReply={currentReply}
						onSelectDelete={onDeleteAttch} type={AT_SAVED}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						onGetAttachmentContent={onGetAttachmentContent} />;
				});
				savedAttachCount = <span>&nbsp;&nbsp;{savedAttachmentsText + ':'} {savedAttachments.length}</span>;

			}
			if (!isForward && questionAttachments.length) {
				qAttachment = questionAttachments.map(item => {
					const id = item.id, {...props} = item;
					return <InsertedOneQuestionAttachment key={id} me={id}
						currentReply={currentReply}
						selected={selectedList[id]} onSelect={onSelect}
						onSelectDelete={onDeleteAttch}
						onGetAttachmentContent={onGetAttachmentContent}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						selectable={canInsertIncomingAttachments} {...props} />;
				});
				qAttachCount = <span> &nbsp;&nbsp;{insertedQAttachmentsText + ':'} {questionAttachments.length}</span>;
			}
			if(collaborateAnsAttachments.length) {
				colAnsAttach = collaborateAnsAttachments.map(item => {
					const id = item.id, {...props} = item;
					return <CollaborateAnsAttachment key={id} me={id}
						currentReply={currentReply}
						onSelectDelete={onDeleteAttch}
						onGetAttachmentContent={onGetAttachmentContent}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview} {...props} />;
				});
				colAnsAttachCount = <span> &nbsp;&nbsp;{insertedColAnsAttachText + ':'} {collaborateAnsAttachments.length}</span>;
			}
		}
		if(uploadedAttachments.length > 0) {
			agentAttchs = uploadedAttachments.map(item => {
				const id = item.id, {...props} = item;
				return <AgentSingleAttachment key={id} me={id} {...props}
					currentReply={currentReply}
					onSelectDelete={onDeleteAttch} type={AT_BROWSE}
					textFilePreview={textFilePreview}
					csvFilePreview={csvFilePreview}
					onGetAttachmentContent={onGetAttachmentContent} />;
			});
			agentAttchCount = <span> | {uploadAttachmentText + ':'} {uploadedAttachments.length}</span>;
		}
		if(archiveFiles.length > 0) {
			archiveAttachments = archiveFiles.map(item => {
				const id = item.id, {...props} = item;
				return <AgentSingleAttachment key={"archive_"+id} me={id} {...props}
					currentReply={currentReply}
					onSelectDelete={onDeleteAttch} type={AT_ARCHIVE}
					textFilePreview={textFilePreview}
					csvFilePreview={csvFilePreview}
					onGetAttachmentContent={onGetAttachmentContent} />;
			});
			archiveAttchCount = <span> &nbsp;&nbsp;{archiveAttachmentsText + ':'} {archiveAttachments.length}</span>;
		}
		if(libraryFiles.length > 0) {
			libraryAttachments = libraryFiles.map(item => {
				const id = item.id, {...props} = item;
				return <AgentSingleAttachment key={"library_"+id} me={id} {...props}
					currentReply={currentReply}
					onSelectDelete={onDeleteAttch} type={AT_LIBRARY}
					textFilePreview={textFilePreview}
					csvFilePreview={csvFilePreview}
					onGetAttachmentContent={onGetAttachmentContent} />;
			});
			libraryAttchCount = <span> &nbsp;&nbsp;{libraryAttachmentsText + ':'} {libraryAttachments.length}</span>;
		}
		if(isCollaboration) {
			let totalSelected = 0;
			hideDownloadBox = true;
			title = TITLE_COLLABORATE_FILES;
			attachmentsClass += ' column';
			if(list) {
				historyAttachments = [];
				$.each(list, (k,v) => {
					let title = (k === 'oldAttachments') ?
						EXT_EXPERT_EDIT_ATTACHMENT_TYPE.oldAttachments.title :
						k + ": ";
					const groupAttachments = (
					<GroupAttachments key={k}
						onSelect={onSelect} title={title}
						onGetAttachmentContent={onGetAttachmentContent}
						textFilePreview={textFilePreview}
						csvFilePreview={csvFilePreview}
						selectedList={selectedList} list={list[k]}
					/>
					)
					if(cflagAttachment) {
						// sort by latest 1st
						historyAttachments.unshift(groupAttachments);
					} else {
						historyAttachments.push(groupAttachments);
					}
				});
				$.each(selectedList, (k,v) => {
					if(v) {
						totalSelected++;
					}
				});
			}
			if (savedAttachments && savedAttachments.length) {
				totalSelected += savedAttachments.length
			}
			if (uploadedAttachments && uploadedAttachments.length) {
				totalSelected += uploadedAttachments.length
			}
			selectedStatus = <span>
					{' | ' + I('Total selected attachments: ') + totalSelected}
				</span>;
			if(agentAttchs && agentAttchs.length) {
				if(archiveAttachments && archiveAttachments.length) {
					agentAttchs = agentAttchs.concat(archiveAttachments);
					archiveAttachments = null;
				}
			} else if(archiveAttachments && archiveAttachments.length) {
				agentAttchs = archiveAttachments;
				archiveAttachments = null;
			} else if(libraryAttachments && libraryAttachments.length) {
				agentAttchs = libraryAttachments;
				libraryAttachments = null;
			}
		} else {
			let totalSelected = 0;
			if(isForward) {
				title = TITLE_ATTACHED_FILES;
				if(list && list.length > 0) {
					attachments = list.map(item => {
						const id = item.id, {...props} = item;
						let selectable = cflag.IsActive("2024-03-26.CEN-2280.exclude.unselected.inline.image") ?
							(item.hasOwnProperty('embeddedImage') === false || item.embeddedImage === false) : true;
						return <OneAttachmentBox key={id} me={id} {...props}
							currentReply={currentReply}
							selected={selectedList[id]} onSelect={onSelect}
							onGetAttachmentContent={onGetAttachmentContent}
							textFilePreview={textFilePreview}
							csvFilePreview={csvFilePreview}
							selectable={selectable} />;
					});
					attachmentsToForward = attachments;
				}
				$.each(selectedList, (k,v) => {
					if(v) {
						totalSelected++;
					}
				});
			} else {
				title = TITLE_ATTACHED_FILES;
				if(list && list.length > 0 && selectedList) {
					attachments = list.map(item => {
						const id = item.id, {...props} = item;
						let selectable = cflag.IsActive("2024-03-26.CEN-2280.exclude.unselected.inline.image") ?
							(item.hasOwnProperty('embeddedImage') === false || item.embeddedImage === false) : true;
						return <OneQuestionAttachment key={id} me={id} {...props}
							currentReply={currentReply}
							selected={selectedList[id]} onSelect={onSelect}
							onGetAttachmentContent={onGetAttachmentContent}
							textFilePreview={textFilePreview}
							csvFilePreview={csvFilePreview}
							selectable={selectable && canInsertIncomingAttachments} />;
					});
				}
			}
			if((isReplyErrand && includeHistory) ||
				(isForward && extForwardHistory)) {
				attachmentsClass += ' column';
				if(historyAttachmentList) {
					historyAttachments = [];
					$.each(historyAttachmentList, (k,v) => {
						let subTitle = k + ": ";
						historyAttachments.push(<GroupAttachments key={k}
							selectedList={selectedHistoryAttachmentList}
							onSelect={onSelect} title={subTitle}
							onGetAttachmentContent={onGetAttachmentContent}
							textFilePreview={textFilePreview}
							csvFilePreview={csvFilePreview}
							list={historyAttachmentList[k]}
							isHistoryAttachment={IS_HISTORY_ATTACHMENT}
						/>);
					});
					$.each(selectedHistoryAttachmentList, (k,v) => {
						if(v) {
							totalSelected++;
						}
					});
					if(historyAttachments.length > 0) {
						if(cflagAttachment) {
							selectAllCheckbox =
								<SquareCheckbox
									id={"HistoryAttachmentsCheckbox"}
									data-qa-id={"qa-rights-to-be-forgotten"}
									checked={allAttachmentChecked}
									onClick={this.handleToggleAllAttachemnt}
									label={I("Include all")}
									title={I("Include all history attachments")}
									className={"attachment-history__selection v5-checkbox big"}
									flip={true}
								/>
						} else {
							selectAllCheckbox = <SidebarSettingsList>
							<ButtonIcon
								className={classNames("btn-toggle all-attachment", {"active" : allAttachmentChecked})}
								icon={"fas fa-paperclip"}
								onClick={this.handleToggleAllAttachemnt}
								title={I("Include all history attachments")}
								noTooltip={true}
								data-qa-id={"btn-history_allAttachment"}/>
							</SidebarSettingsList>
						}
					}
				}
		    }
			if (savedAttachments && savedAttachments.length) {
				totalSelected += savedAttachments.length
			}
			if (uploadedAttachments && uploadedAttachments.length) {
				totalSelected += uploadedAttachments.length
			}
			selectedStatus = <span>
				{' '+totalSelected+ ' ' + I('Selected ')}
			</span>;
		}

		let hasHistory = false;
		if(includeHistory) {
			if(Array.isArray(historyAttachmentList)) {
				hasHistory = historyAttachmentList.length > 0;
			}
			else {
				hasHistory = Object.keys(historyAttachmentList).length > 0;
			}
		}
		let prefix = filePathPrefix()
		let currentConversation = <div className="this-conversation">
									<div className="attachment-current">
										<div className="headings">{title + ': ' + size}</div>
										{list && list.length > 0 ? attachments : <NoFilesThumbnail label={I("No files available")} />}
									</div>
									<div className="attachment-history" hidden={!hasHistory}>
										<div className="headings with-checkbox">
											<span>{I("Previous Errand:")}</span>
											{selectAllCheckbox}
										</div>
										{historyAttachments}
									</div>
								</div>
		return <div className="errand-box-attachments">
				<div className="attachment-row attachment-info">
					<div className="col-sm-9 header_title">
						
						{selectedStatus}
						{agentAttchCount}
						{savedAttachCount}
						{qAttachCount}
						{archiveAttchCount}
						{libraryAttchCount}
						{colAnsAttachCount}
						
					</div>
					{cflagAttachment ?
					// new GUI
					<AttachmentOptionsDD
						hidden={hideDownloadBox}
						show={this.state.showAttDownloadBox}
						onDelete={this.handleDeleteAllAttachs}
						downloadSrc={`${prefix}errand/downloadAttachments/${errand.id}`}
					/>
					:
					<div className="col-sm-3 content-right info-options"
						hidden={hideDownloadBox}>
						<div id="attachment-messagebox" className="messagebox-hide-files"
							hidden={this.state.showAttDownloadBox}>
							<div className="toggle-files-icon" onClick={this.openAttDownloadBox}><i className="icon-spread" /></div>
						</div>
						<div className='messagebox'
							hidden={!this.state.showAttDownloadBox}>
							<div className="hide-files">
								<div className="toggle-files-icon" onClick={this.closeAttDownloadBox}><i className="icon-spread" /></div>
							</div>
							<ul>
								<li>
									<a href={`${prefix}errand/downloadAttachments/${errand.id}`}>
										{I('Download All')}
									</a>
								</li>
								<li><a href="#" onClick={this.handleDeleteAllAttachs}>{I('Delete All')}</a></li>
							</ul>
						</div>
					</div>
					}
				</div>
				{cflagAttachment
				?
				// new GUI
				<div className={"item-wrapper " + attachmentsClass}>
					<div className="attachment-row-group attachment-draft">
						<FileUploadDropArea
							onUploadAttachment={this.props.onHandleAttachments}
							uploadTo="errand/uploadAnswerAttachment"
						>
						<FileUploader
							iconClass={"icon-upload"}
							cssClass="file__upload--dropper"
							title={<span className="label">{I("Drag files here or click to select")}</span>}
							uploadTo="errand/uploadAnswerAttachment"
							attachSingle={attachFile.single}
							multipleFile={attachFile.multiple}
							uploadAttachment={this.props.onHandleAttachments}
						/>
						{attachmentsToForward}
						{savedAttachment}
						{qAttachment}
						{agentAttchs}
						{archiveAttachments}
						{libraryAttachments}
						{colAnsAttach}
						</FileUploadDropArea>
					</div>
					<AttachmentTabs
						hidden={currentReply === RPLY_COMMENT}
						currentReply={currentReply}
						chat={chat}
						errandsPanel={
							currentReply === RPLY_ERRAND ? currentConversation
							:
							<div className="attachment-history">
								{selectAllCheckbox}
								{historyAttachments}
							</div>
						}
						archivePanel={
							<ImageArchiveThumbnails
								data={archiveImgs}
								onSelectItem={onSelectArchives}
							/>
						}
						collaborationPanel={
							<CollaborationThumbnails
								data={collaborationAttachments}
								onSelectItem={onInsertCollabAnswerAttachment}
							/>
						}
					/>
				</div>
				:
				<div className={"item-wrapper " + attachmentsClass}>
					<div className="attachment-row-group">
						{attachments}
						{savedAttachment}
						{qAttachment}
						{agentAttchs}
						{archiveAttachments}
						{libraryAttachments}
						{colAnsAttach}
					</div>
					{selectAllCheckbox}
					{historyAttachments}
				</div>
				}
			</div>;
	}
}

class Tag extends React.PureComponent {
	constructor(props) {
		super(props);
		this.displayName = "Tag";
		this.handleClick = this.handleClick.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleClick(e) {
		console.log('dbg: clicked attachment tag', {e, type: this.props.type, me: this.props.me});
		if(this.props.preview) {
			e.preventDefault();
			this.props.onClick(this.props.type, this.props.me);
		}
	}
	handleDelete(e) {
		console.log('dbg: clicked delete attachment tag', {e, type: this.props.type, me: this.props.me});
		if(this.props.showDelete) {
			this.props.onDelete(this.props.type, this.props.me,
				this.props.index, e, this.props.fileName);
		}
	}
	handleSelect(e) {
		console.log('dbg: clicked select attachment tag', {e, type: this.props.type, me: this.props.me});
		if(this.props.showSelect || this.props.showInsert) {
			this.props.onSelect(this.props.type, this.props.me,
				this.props.index, e);
		}
	}
	render() {
		const {
			createdAt,
			csvFilePreview,
			download,
			downloadURL,
			sizeHuman,
			estSize,
			fileName,
			id,
			preview,
			selected,
			showDelete,
			showInsert,
			showSelect,
			showSize,
			src,
			target,
			textFilePreview
		} = this.props, {icon, type, image} = fileNameToIcon(fileName);
		let deleteDOM, selectOrInsertDOM, sizeDOM;
		let validUrl = download;
		if(typeof download === "undefined"){
			validUrl = downloadURL;
		}
		if(showDelete) {
			deleteDOM = <i className='fa fa-times delete' aria-hidden="true"
				onClick={this.handleDelete} />;
		} else {
			deleteDOM = null;
		}
		if(showSelect) {
			selectOrInsertDOM = <SquareCheckbox onClick={this.handleSelect}
				checked={selected} className="file-opr" />;
		} else if(showInsert) {
			selectOrInsertDOM = <i className="fa fa-plus-square file-opr"
				aria-hidden="true" onClick={this.handleSelect} />;
		} else {
			selectOrInsertDOM = null;
		}
		if(showSize && (sizeHuman || estSize)) {
			sizeDOM = <span className='size'>
					{sizeHuman ? sizeHuman : humanByteSize(estSize)}
				</span>;
		} else {
			sizeDOM = null;
		}
		if(typeof validUrl !== "undefined") {
			if(hasQueryParams(download)) {
				if(!hasQueryViewParams(download)) {
					validUrl = validUrl + "&q=view";
				}
			} else {
				validUrl = validUrl + "?q=view";
			}
		}

		return <span className="errand-attachment-list-tag">
				{selectOrInsertDOM}
				<i className={icon + ' file-icon'} aria-hidden="true" />
				<a className='link' href={validUrl} target='_blank' data-qa-id={"msg-attachment-"+fileName}
					onClick={this.handleClick}>
					{fileName}
				</a>
				{sizeDOM}
				{deleteDOM}
			</span>;
	}
}

const UploadedTag = ({value, download, ...props}) => <Tag atType="agent"
	download={download} src={download} fileName={value} {...props} />;

const InsertableTag = ({showInsert, showSelect, ...props}) => <Tag
	showInsert={true} showSelect={false} {...props} />;

export const TagList = ({list, type, ...props}) => {
	let attachments = [], AnyTag;
	if(type === AT_UPLOADED || type === AT_ARCHIVE || type === AT_LIBRARY) {
		AnyTag = UploadedTag;
	} else if(type === AT_COL_ANS) {
		AnyTag = InsertableTag;
	} else {
		AnyTag = Tag;
	}
	$.each(list, (i,v) => {
		const {id, ...items} = v;
		attachments.push(<AnyTag key={id+type} me={id} type={type} index={i}
			{...items} {...props} />);
	});
	return <span className="errand-attachment-list">{attachments}</span>;
};

// like tag list but take in value object {[type]: {list, title}}.
export const SerialTagList = ({value, ...props}) => {
	if(!value) {
		return null;
	}
	let attachments = [];
	$.each(value, (k,v) => {
		attachments.push(<TagList key={k} list={v.list}
			type={k} {...props} />);
	});
	return <span className="errand-attachment-list serial">{attachments}</span>;
};
